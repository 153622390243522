import { useInView } from "react-intersection-observer";
import { BsArrowRight } from "react-icons/bs";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  link: string;
}

export default function ButtonSlide(props: Props) {
  const [refbutton, inView] = useInView({
    // triggerOnce: true,
    threshold: 0.5, // Percentage of visibility required to trigger the animation
  });

  return (
    <div
      className="flex p-[10px] my-[30px] w-fit duration-200 items-center  "
      ref={refbutton}
      style={{ opacity: inView ? 1 : 0 }}
    >
      <Link to={props.link}>
        <motion.div
          initial={{ x: "-100vw" }}
          animate={{ x: 0 }}
          transition={{
            duration: 0.4,
            delay: 0.3,
            type: "spring",
            stiffness: "70",
          }}
        >
          <div className="flex items-center text-query ">
            {props.title}
            <p className=" ml-[5px] text-[20px] pt-[5px] ">
              <BsArrowRight />
            </p>
          </div>
        </motion.div>
      </Link>
    </div>
  );
}
