import { memo, useState } from "react";
import Layout from "../../Layout/Layout";
import { Today } from "../../utils/date";
import Pagination from "../../components/Pagination";
import { FaCheckCircle, FaTimes } from "react-icons/fa";

export default function JointWithdrawal() {
  const [currentPage, setCurrentPage] = useState(2);
  const totalPages = 3;
  return (
    <Layout>
      <div className=" relative  min-w-0 break-words w-full  ">
        <p className=" text-[20px] md:text-[25px] font-[700] ">
          Requests For Joint
        </p>
        <div className="dash_webkit block pt-10 w-full overflow-x-auto ">
          <table className=" bg-red-00 w-full md:w-full ">
            <thead className=" mb-7 ">
              <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Name
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Amount
                </th>

                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Status
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#d7d7d7]">
              <TableRow
                fullname="Uche Abangwu"
                amount={500}
                status="Pending"
                date={Today}
              />
              <TableRow
                fullname="Uche Abangwu"
                amount={500}
                status="Pending"
                date={Today}
              />

              <tr>
                <td className="pt-6" colSpan={5}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    sendRequest={setCurrentPage}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

const TableRow = memo(
  ({
    fullname,
    amount,
    status,
    date,
  }: {
    fullname: string;
    amount: number;
    date: string;
    status: string;
  }) => {
    return (
      <tr className={`text-left border-b-2  border-[#d7d7d7]`}>
        <td className="py-5 whitespace-nowrap px-3">{fullname}</td>
        <td className="py-5 whitespace-nowrap px-3">${amount}</td>
        <td className="py-5 whitespace-nowrap px-3">{status}</td>
        <td className="py-5 whitespace-nowrap px-3">{date}</td>
        <td className="py-5 whitespace-nowrap px-3">
          <div className="flex gap-3 items-center ">
            <button title="Approve Deposit">
              <FaCheckCircle fill="green" />
            </button>
            <button title="Reject Deposit">
              <FaTimes fill="red" />
            </button>
          </div>
        </td>
      </tr>
    );
  }
);
