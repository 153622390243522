import { Outlet, Navigate } from "react-router-dom";
import { authConfig } from "../utils/Constants";
import { useSelector } from "react-redux";
import { SelectAuth } from "../store/slice/auth";

export const ProtectedRoute = () => {
  let token = localStorage.getItem(authConfig.storageTokenKeyName);

  return token ? <Outlet /> : <Navigate to={`/login`} />;
};

export const NoAccessRoute = () => {
  const { user } = useSelector(SelectAuth);

  let token = localStorage.getItem(authConfig.storageTokenKeyName);

  return token === null ? (
    <Outlet />
  ) : (
    <Navigate
      to={`${
        user && user?.type === "CUSTOMER" ? "/dashboard" : "/admin/dashboard"
      }`}
    />
  );
};
