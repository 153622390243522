export default function Section1() {
  return (
    <div>
      <div className="bg-main w-full flex ">
        <div className=" px-[50px] md:py-[20px] md:w-[70%] flex flex-wrap m-auto flex-col md:flex-row justify-between ">
          <div className=" flex md:w-[30%] text-white min-h-[10px] md:min-h-[100px] p-[10px] border-r-0 md:border-r-2 border-b-2 md:border-b-0 mt-[30px] flex-col  ">
            <p className="pb-[10px] text-[16px] md:text-[18px] font-bold ">
              CONSISTENT
            </p>
            <p className=" pb-[20px] text-sub_main text-[17px] md:text-[19px] mt-[10px]">
              Focusing on dividend growth and power of compounded interest
            </p>
          </div>
          <div className=" flex md:w-[30%] text-white min-h-[10px] md:min-h-[100px] p-[10px] border-r-0 md:border-r-2 border-b-2 md:border-b-0 mt-[30px] flex-col  ">
            <p className="pb-[10px] text-[16px] md:text-[18px] font-bold ">
              INVESTMENT PHILOSOPHY
            </p>
            <p className=" pb-[20px] text-sub_main text-[17px] md:text-[19px] mt-[10px]">
              Actively manage asset allocation and sector allocation
            </p>
          </div>

          <div className=" flex md:w-[30%] text-white min-h-[10px] md:min-h-[100px] p-[10px] border-r-0 md:border-r-2 border-b-2 md:border-b-0 mt-[30px] flex-col  ">
            <p className="pb-[10px] text-[16px] md:text-[18px] font-bold ">
              LONG TERM
            </p>
            <p className=" pb-[20px] text-sub_main text-[17px] md:text-[19px] mt-[10px]">
              Hedging strategies can be used for risk reduction purposes
            </p>
          </div>
        </div>
      </div>
      <div className=" flex flex-col md:flex-row relative ">
        <div className=" bg-white py-[35px] px-[30px] min-h-[250px] ">
          <h1 className=" pb-[0px] text-sub_main text-[20px] md:text-[30px] ">
            The Ark experience
          </h1>
          <div>
            <p className="py-[20px] text-[14px] md:text-[18px] ">
              An actively managed Exchange Traded Fund (ETF) that seeks
              long-term growth of capital by investing under normal
              circumstances primarily (at least 80% of its assets) in domestic
              and foreign equity securities of autonomous technology and
              robotics companies that are relevant to the Fund’s investment
              theme of disruptive innovation
            </p>
            <p className=" pb-[20px] text-[14px] md:text-[18px] ">
              We are one of the world’s leading providers of investment,
              advisory and risk management solutions. We are a fiduciary to our
              clients. We’re investing for the future on behalf of our clients,
              inspiring our employees, and supporting our local communities. 
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}
