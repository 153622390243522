import React, { forwardRef } from "react";

const SwitchButton = forwardRef<
  HTMLInputElement,
  { checked: boolean; onChange: (value: any) => void }
>((props, fowardedRef) => (
  <label className=" cursor-pointer ">
    <input
      type="checkbox"
      className="hidden"
      name=""
      id=""
      ref={fowardedRef}
      {...props}
    />
    <div
      className={`w-16 h-8 py-2 px-4  relative  rounded-full ${
        props.checked ? "bg-blue-700" : " bg-gray-400 "
      } `}
    >
      <div
        className={`w-fit p-2 absolute shadow-sm transition-all rounded-full duration-300 bg-white ${
          props.checked ? " right-1 " : "left-1"
        } `}
      ></div>
    </div>
  </label>
));

export default SwitchButton;
