import { MdCheckCircle } from "react-icons/md";
import LayoutOne from "../Layout/Layout_One";
import Imgsection from "../components/Comp_Layouts/HeroTwo";

export default function Terms() {
  return (
    <LayoutOne>
      <Imgsection image="/images/" title="Terms" />
      <div className=" text-black w-[90%] m-auto ">
        <p className=" font-bold text-[20px] md:text-[25px] py-6">
          Terms & Conditions
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          Welcome to Ark investment management limited, an actively managed ETF
          & hedge fund that provides investment services to individuals and
          entities seeking to grow and manage their financial assets. By using
          our services, you agree to be bound by these Terms and Conditions.
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          Our mission is to offer diverse investment opportunities and
          personalized strategies to help you achieve your financial goals.
          These Terms and Conditions govern your use of our website, mobile
          application, and other services we provide (collectively referred to
          as the "Platform").
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          Throughout these Terms and Conditions, "we," "us," or "our" refers to
          [Ark investment management limited ], and "you" or "your" refers to
          the user accessing or using our Platform. By accessing or using the
          Platform, you acknowledge that you have read, understood, and agreed
          to these Terms and Conditions. If you do not agree with any part of
          these Terms and Conditions, please refrain from using our services.
        </p>
        <ul className=" gap-4 text-[13px] md:text-[16px] w-[98%] md:w-[78%] m-auto py-[20px] text-black ">
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />
              <p>
                <b>AIML:</b> Stands for Ark investment Management limited
              </p>
            </div>
          </li>
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />

              <p>
                <b>Platform:</b>Refers to [AIML] website, mobile application,
                and any other services we provide.
              </p>
            </div>
          </li>
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />

              <p>
                <b>Account:</b>A registered user's personal investment account
                on the Platform.
              </p>
            </div>
          </li>
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />

              <p>
                <b>Investment Services:</b>The various investment products,
                strategies, and tools offered by [AIML ] through the Platform.
              </p>
            </div>
          </li>
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />

              <p>
                <b>Eligible User:</b> An individual or entity that meets the
                criteria specified in the Eligibility section below.
              </p>
            </div>
          </li>
          <li>
            <div className="flex my-3 items-center ">
              <MdCheckCircle className="mx-3" color="black" />

              <p>
                <b>User Content:</b>Any information, data, text, images, or
                other materials submitted by users through the Platform.
              </p>
            </div>
          </li>
        </ul>
        <p className=" font-bold text-[20px] md:text-[25px] py-6 ">
          Eligibility
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          To use our Investment Services, you must be of legal age in your
          country of residence and have the legal capacity to enter into binding
          contracts. If you are accessing the Platform on behalf of an entity,
          you must have the necessary authority to bind that entity to these
          Terms and Conditions.
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          By using the Platform, you represent and warrant that all information
          you provide during the registration process is accurate, complete, and
          up-to-date. You are responsible for maintaining the confidentiality of
          your account credentials and for all activities that occur under your
          account.
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          Ark investment management limited reserves the right to refuse
          service, terminate accounts, or restrict access to the Platform at our
          discretion, including, but not limited to, cases of suspected
          fraudulent or unauthorized activities.
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          If you are accessing the Platform from a location outside the
          jurisdiction of Ark investment Management limited, you are responsible
          for complying with all local laws and regulations related to the use
          of our services.
        </p>

        <p className=" font-bold text-[20px] md:text-[25px] py-6 ">
          Account Registration
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          To access the full range of Investment Services provided by [Ark
          investment Management limited], you must complete the account
          registration process. During registration, you will be required to
          provide accurate and up-to-date personal information, including your
          full name, contact details, and any other information deemed
          necessary.
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          You are solely responsible for maintaining the confidentiality of your
          account credentials, including your username and password. Any
          activities that occur under your account are your responsibility, and
          you agree to notify us immediately of any unauthorized use or security
          breaches.
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          By registering an account, you consent to receive communications from
          Ark investment Management limited via email, phone, or other means,
          regarding your account, investments, and important updates related to
          our services.
        </p>
        <p className=" pb-3 text-[13px] md:text-[16px] ">
          Ark investment Management limited, reserves the right to verify your
          identity during the registration process or at any point while using
          the Platform. Failure to provide accurate information or comply with
          identity verification requests may result in the suspension or
          termination of your account.
        </p>

        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Investment Services
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Ark investment Management limited, offers a variety of Investment
            Services designed to meet the diverse needs of our users. These
            services may include but are not limited to investment advisory
            services, portfolio management, and access to various investment
            products.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            While we strive to provide accurate and reliable information, all
            investment services are subject to market risks and uncertainties.
            Past performance is not indicative of future results, and there are
            no guarantees regarding investment returns.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            The investment strategies and products recommended on the Platform
            are based on various factors, including your risk tolerance,
            investment goals, and market conditions. However, it is essential to
            understand that all investments carry inherent risks, and you should
            carefully consider your risk tolerance before making any investment
            decisions.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            You acknowledge that you are solely responsible for making your
            investment choices and are encouraged to seek advice from qualified
            financial advisors before making significant investment decisions.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Fees and Expenses
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Ark investment Management limited, charges fees for certain
            Investment Services provided through the Platform. The specific fees
            applicable to your account will be disclosed during the account
            registration process and are subject to change.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            By using our Investment Services, you agree to pay all applicable
            fees and expenses associated with your account. Fees may include
            account management fees, transaction fees, and other charges as
            outlined in our fee schedule.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            It is your responsibility to review and understand the fee schedule,
            including any changes to fees that may occur over time. We will
            provide reasonable notice of any fee changes, and continued use of
            the Platform after such notice constitutes your acceptance of the
            updated fees.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Some investment products and services recommended on the Platform
            may also have their own associated fees and expenses. These charges
            will be disclosed in the respective investment product's
            documentation.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Investment Risks
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Investing in financial markets involves inherent risks. The value of
            investments can fluctuate, and there is no guarantee that you will
            achieve positive returns on your investments.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            <p>
              {" "}
              <b> Market risk:</b> Changes in market conditions, economic
              factors, or geopolitical events may lead to fluctuations in the
              value of investments.
            </p>
            <p>
              {" "}
              <b> Credit risk:</b> Some investments may be subject to credit
              risk, where the issuer of a financial product may default on
              interest or principal payments.
            </p>
            <p>
              {" "}
              <b> Currency risk:</b> Investments denominated in foreign
              currencies are subject to exchange rate fluctuations, which may
              affect returns.
            </p>
            <p>
              {" "}
              <b> Past performance risk:</b> Past performance is not indicative
              of future results, and historical returns should not be the sole
              basis for investment decisions.
            </p>
            <p>
              {" "}
              <b> Past performance risk:</b> Past performance is not indicative
              of future results, and historical returns should not be the sole
              basis for investment decisions.
            </p>
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            It is essential to carefully consider these risks and your risk
            tolerance before making any investment decisions. Ark investment
            Management limited, does not provide personalized investment advice,
            and users are encouraged to seek advice from qualified financial
            advisors.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Privacy Policy
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Ark investment management limited, respects your privacy and is
            committed to protecting your personal information. Our Privacy
            Policy outlines how we collect, use, and safeguard your data.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            <p>
              {" "}
              <b> Personal information:</b> We may collect personal information,
              including but not limited to your name, contact details, financial
              information, and investment preferences. This information is used
              to provide our services and to comply with legal requirements.
            </p>
            <p>
              {" "}
              <b> Data security:</b> We implement security measures to protect
              your personal information from unauthorized access, loss, or
              disclosure.
            </p>
            <p>
              {" "}
              <b> Data sharing:</b> We may share your information with trusted
              third-party service providers, as necessary to provide our
              services. We do not sell or rent your personal information to
              third parties for marketing purposes.
            </p>
            <p>
              {" "}
              <b> Cookies and tracking technologies:</b> We may use cookies and
              other tracking technologies to enhance your experience on our
              Platform. You can manage your cookie preferences through your
              browser settings.
            </p>
            <p>
              {" "}
              <b> Changes to the Privacy Policy:</b> We may update our Privacy
              Policy from time to time. Any changes will be posted on our
              website, and your continued use of the Platform after such changes
              implies acceptance of the updated Privacy Policy.
            </p>
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Termination
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            You may terminate your account and use of the Platform at any time
            by following the applicable account closure procedures
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Ark investment management limited, reserves the right to terminate
            your account or suspend your access to the Platform for any reason,
            including suspected fraudulent or unauthorized activities.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Termination may result in the loss of access to your account and any
            associated investment data. You are responsible for withdrawing your
            funds before account termination if applicable.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Termination may result in the loss of access to your account and any
            associated investment data. You are responsible for withdrawing your
            funds before account termination if applicable.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Provisions relating to confidentiality, intellectual property
            rights, limitation of liability, and governing law will survive
            termination and Upon termination, any outstanding fees or expenses
            will become due and payable. .
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Limitation of Liability
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Ark investment Management limited] and its affiliates, directors,
            officers, employees, and agents shall not be liable for any direct,
            indirect, incidental, consequential, or punitive damages arising
            from your use of the Platform or reliance on its content.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Ark investment Management limited, does not make any warranties or
            representations regarding the accuracy, reliability, or completeness
            of the information provided through the Platform.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            The Platform may contain links to third-party websites or services.
            Ark investment Management limited, is not responsible for the
            content, privacy policies, or practices of these third-party sites.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Ark investment Management limited, is not liable for any losses,
            damages, or delays caused by events beyond our reasonable control,
            including but not limited to, technical failures, acts of nature, or
            cyberattacks
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Governing Law and Dispute Resolution
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of England, without regard to its conflict
            of law principles.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Any dispute, claim, or controversy arising out of or relating to
            these Terms and Conditions, the Platform, or our Investment Services
            shall be resolved through binding arbitration in accordance with the
            rules of Arbitration Association.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            You and Ark investment Management limited agree to waive any right
            to participate in a class action or representative proceeding
            concerning any dispute arising from or related to these Terms and
            Conditions or the Platform.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            The decision of the arbitrator(s) shall be final and binding upon
            both parties, and judgment upon the award rendered may be entered in
            any court having jurisdiction.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Entire Agreement
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            These Terms and Conditions constitute the entire agreement between
            you and Ark investment Management limited regarding your use of the
            Platform and supersede any prior agreements, understandings, or
            representations.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            If any provision of these Terms and Conditions is deemed invalid or
            unenforceable, the remaining provisions shall remain in full force
            and effect.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Failure by Ark investment Management limited to enforce any
            provision of these Terms and Conditions shall not be construed as a
            waiver of such provision.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            These Terms and Conditions are not intended to create any
            partnership, joint venture, agency, or employment relationship
            between you and Ark investment Management limited.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Intellectual Property
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            All content, materials, trademarks, logos, and intellectual property
            displayed or accessible through the Platform are the exclusive
            property of Ark investment management limited, or its licensors.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            You may not use, copy, modify, distribute, or reproduce any content
            or intellectual property from the Platform without prior written
            consent from Ark investment management limited.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            You may use the Platform solely for personal, non-commercial
            purposes, and any other use is strictly prohibited.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            By submitting any User Content through the Platform, you grant Ark
            investment management limited, a worldwide, non-exclusive,
            royalty-free, and transferable license to use, reproduce,
            distribute, and display such User Content for the purpose of
            providing our services.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Indemnification
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            You agree to indemnify, defend, and hold harmless Ark investment
            Management limited and its affiliates, directors, officers,
            employees, and agents from and against any claims, losses,
            liabilities, damages, expenses, and costs (including reasonable
            attorney fees) arising from your use of the Platform, violation of
            these Terms and Conditions, or infringement of any third-party
            rights.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Ark investment Management limited reserves the right to assume
            exclusive defense and control of any matter subject to
            indemnification by you, in which case you agree to cooperate fully
            with Ark investment Management limited in asserting any available
            defenses.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Amendment
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Ark investment Management limited reserves the right to amend these
            Terms and Conditions at any time and for any reason.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            Any updates or changes to these Terms and Conditions will be posted
            on the Platform, and the date of the last revision will be indicated
            at the top of the document.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            By continuing to use the Platform after any changes are made, you
            agree to be bound by the revised Terms and Conditions.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            It is your responsibility to review these Terms and Conditions
            regularly to stay informed of any updates.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Severability
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            In the event of such severance, the parties shall negotiate in good
            faith to replace the invalid, unlawful, or unenforceable provision
            with a valid and enforceable provision that achieves the original
            intent of the parties to the extent possible.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            If any provision of these Terms and Conditions is found to be
            invalid, unlawful, or unenforceable by a court of competent
            jurisdiction, such provision shall be deemed severed from these
            Terms and Conditions, and the remaining provisions shall remain
            valid and enforceable to the fullest extent permitted by law.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Complaints and Compensation
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            AIML has established procedures in accordance with the FCA Rules for
            consideration of complaints. Details of these procedures are
            available upon request. Should the Investor have a complaint he or
            she should contact Ark Financial Management. If Ark Financial
            Management cannot resolve the complaint to the satisfaction of the
            Investor, the Investor may be entitled to refer it to the Financial
            Ombudsman Service. However, the Investor is warned that if he or she
            has been classified as a Professional Client, the Financial
            Ombudsman Service may consider him ineligible to complain
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            AIML participates in the Financial Services Compensation Scheme,
            established under FSMA, which provides compensation to an eligible
            Investor in the event of a firm being unable to meet its liabilities
            to clients. Payments under the protected investment business scheme
            are limited to a maximum of the first £50,000 of the claim. Further
            information is available from Ark Financial Management. However, the
            Investor is warned that if he or she is classified as a Professional
            Client, he or she may not be eligible under the criteria of the
            Financial Services Compensation Scheme to participate, and is
            further warned that failure of an Investment to deliver a financial
            return is not a basis for a claim under the Financial Services
            Compensation Scheme in any event
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Conflicts of Interest
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            AIML may provide custodian, nominee, administrative or other
            services to any person and shall not in any circumstances be
            required to account to the Investor for any profits earned in
            connection therewith. In handling conflicts, Ark Financial
            Management represents and warrants that it will act in compliance
            with FCA Rules.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            AIML is required by FCA Rules to establish, implement and maintain a
            conflicts of interest policy, which sets out the types of actual or
            potential conflicts of interest which may affect Ark Financial
            Management. A copy of this policy will be provided on request.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            AIML or any Associate may hold investments in any Investee Company.
          </p>
        </div>
        <div>
          <p className=" font-bold text-[20px] md:text-[25px] py-6    ">
            Transfer of Interests
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            On the death or bankruptcy of the Investor, his or her personal
            representatives or trustee (as the case may be) shall become the
            “Investor” in his or her place and entitled to receive any
            distribution of monies under the Contract as the “Investor”.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            On termination of the Contract, the Investments shall (unless
            otherwise agreed by the Investor) be transferred into the name of
            the Investor (as the underlying beneficial owner of the Investments)
            or to another nominee appointed by the Investor.
          </p>
          <p className=" pb-3 text-[13px] md:text-[16px] ">
            The Investor undertakes not to instruct a transfer in accordance
            with this Condition 8 without the prior written approval of AIML.
          </p>
        </div>
      </div>
    </LayoutOne>
  );
}
