import { memo, useEffect } from "react";
import Layout from "../Layout/Layout";
import { Fmtd_Date } from "../utils/date";
import { generateTableRandomColor } from "../utils/functions";
import Pagination from "../components/Pagination";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { getWithdrawals } from "../store/service/investment";
import { SelectInvestment } from "../store/slice/investment";
import { ScaleLoader } from "react-spinners";

export default function ViewWithdrawals() {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { withdrawal, loading, pagination } = useSelector(SelectInvestment);

  const FetchWithdrawals = async (params: { page: number } | null) => {
    try {
      await dispatch(getWithdrawals(params));
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequest = (val: number) => {
    return FetchWithdrawals({ page: val });
  };

  useEffect(() => {
    FetchWithdrawals(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Layout>
      <div className=" relative  min-w-0 break-words w-full  ">
        <p className=" text-[20px] md:text-[25px] font-[700] ">
          User's Withdrawals
        </p>
        <div className=" block dash_webkit pt-10 w-full overflow-x-auto ">
          <table className=" bg-red-00 w-full md:w-full ">
            <thead className=" mb-7 ">
              <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  WIthdrawal Id
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Amount
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Status
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Date Of Withdrawal
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#d7d7d7]">
              {!loading &&
                withdrawal.length !== 0 &&
                withdrawal?.map((withdrawal) => (
                  <TableRow
                    key={withdrawal?.id}
                    color={generateTableRandomColor()}
                    withdrawal_id={withdrawal?.id}
                    amount={withdrawal?.profit}
                    status={withdrawal?.status}
                    date={Fmtd_Date(withdrawal?.created_at)}
                  />
                ))}

              {!loading && withdrawal.length === 0 && (
                <tr>
                  <td className="py-0 text-center " colSpan={5}>
                    <div className="w-full py-10 font-bold bg-main text-white text-[20px] flex justify-center items-center ">
                      You Have No History Of Withrawals
                    </div>
                  </td>
                </tr>
              )}

              {loading && (
                <tr>
                  <td className="py-6 text-center " colSpan={5}>
                    <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                      <ScaleLoader />
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td className="pt-6" colSpan={5}>
                  <Pagination
                    currentPage={pagination?.current}
                    totalPages={pagination?.total}
                    sendRequest={sendRequest}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

const TableRow = memo(({ color, withdrawal_id, amount, status, date }: any) => {
  return (
    <tr
      className={`text-left border-b-2 border-[#d7d7d7]`}
      style={{ background: color }}
    >
      <td className="py-5 whitespace-nowrap px-3">{withdrawal_id}</td>
      <td className="py-5 whitespace-nowrap px-3">${amount}</td>
      <td className="py-5 whitespace-nowrap px-3">{status}</td>
      <td className="py-5 whitespace-nowrap px-3">{date}</td>
    </tr>
  );
});
