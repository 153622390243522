import React from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { SelectAuth } from "../../store/slice/auth";

interface NavItemProps {
  name: string;
  url: string;
  icon: any;
  status: boolean;
}

export const NavLink = ({ url, name, icon }: NavItemProps) => {
  const pathname = useLocation().pathname;
  const { user } = useSelector(SelectAuth);

  return (
    <>
      {!user?.joint_id ? (
        <>
          {name !== "Joint Account" && (
            <Element icon={icon} name={name} pathname={pathname} url={url} />
          )}
        </>
      ) : (
        <Element icon={icon} name={name} pathname={pathname} url={url} />
      )}
    </>
  );
};

const Element = ({
  pathname,
  icon,
  url,
  name,
}: {
  pathname: string;
  url: string;
  icon: any;
  name: string;
}) => (
  <Link to={url}>
    <div
      className={`flex border-b-2 md:border-b-0 pb-6 mt-10 md:mt-10  md:pb-3 hover:bg-white hover:pt-3 hover:md:rounded-tl-3xl hover:md:rounded-bl-3xl hover:text-sub_main w-full items-center gap-5 px-5   text-sub_main ${
        pathname === url
          ? "font-semibold text-[18px] md:rounded-tl-3xl md:rounded-bl-3xl bg-sub_main text-white pt-3 w-full md:text-[16px] ml-0 md:ml-0 "
          : ""
      }`}
    >
      <div className="text-[25px] ">{icon && React.createElement(icon)}</div>{" "}
      {name}
    </div>
  </Link>
);
