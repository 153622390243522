import { ReactNode, createElement } from "react";
import classNames from "classnames";

type Props = {
  error?: string;
  name: string;
  className: string;
  type?: string;
  children?: ReactNode;
  value?: string | number;
  onChange?: (event: any) => void;
  onBlur?: (event: any) => void;
  icon?: any;
  margin?: number;
  label?: string;
  onClick?: (event: any) => void;
};
export const FormInput = ({
  error,
  name,
  type,
  onBlur,
  onChange,
  value,
  className,
  margin,
  icon,
  label,
  onClick,
}: Props) => {
  return (
    <div>
      <div
        className={`flex justify-between items-center mt-[${
          margin ? margin : "30px"
        }] `}
      >
        <p className=" mb-2"> {label}</p>
        <p className={` text-red-500 text-[12px] mb-2 `}>{error}</p>
      </div>
      <div className="flex items-center bg-ubmain px-3 ">
        <input
          className={classNames(
            `${className} outline-none text-[14px] py-[17px] md:py-[18px] w-full px-[10px] md:px-[34px] mt-[0px] bg-ubmain `
          )}
          placeholder={name}
          autoComplete="new-password"
          name={name}
          value={value || ""}
          type={type}
          onBlur={onBlur}
          onChange={onChange}
        />
        {icon && (
          <button
            type="button"
            className=" font-bold text-lg "
            onClick={onClick}
          >
            {createElement(icon)}
          </button>
        )}
      </div>
    </div>
  );
};

export const SelectInput = ({
  error,
  name,
  children,
  onBlur,
  onChange,
  value,
  label,
}: Props) => {
  return (
    <div>
      <div className="mt-[30px] flex justify-between items-center ">
        <p>{label}</p>

        <p className="text-right  text-red-500 text-[12px] ">{error}</p>
      </div>
      <select
        className=" outline-none text-[14px] py-[17px] md:py-[18px] w-full px-[10px] md:px-[34px] mt-[10px] bg-ubmain "
        autoComplete="new-password"
        name={name}
        value={value || ""}
        onBlur={onBlur}
        onChange={onChange}
      >
        {children}
      </select>
    </div>
  );
};

export const TextAreaInput = ({
  error,
  name,
  children,
  onBlur,
  onChange,
  value,
  label,
}: Props) => {
  return (
    <div>
      <div className=" flex items-center justify-between mb-[10px]">
        <p>{label}</p>

        <p className="text-right text-red-500  ">{error}</p>
      </div>

      <textarea
        className=" outline-none text-[14px] py-[17px] md:py-[18px] h-[500px] w-full px-[10px] md:px-[34px] mt-[0px] bg-ubmain "
        autoComplete="new-password"
        name={name}
        value={value || ""}
        onBlur={onBlur}
        onChange={onChange}
      >
        {children}
      </textarea>
    </div>
  );
};
