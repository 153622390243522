import { useEffect, useState } from "react";
import { useReset } from "../Hooks/authHooks";
import LayoutOne from "../Layout/Layout_One";
import { FormInput } from "../components/Comp_Layouts/Form";
import Imgsection from "../components/Comp_Layouts/HeroTwo";
import { Link, useParams } from "react-router-dom";
import { Button } from "../components/Button";
import { routeObj } from "../constants/routes";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";

export default function ResetPassword() {
  const { reset } = useReset();
  const { reset: resetToken } = useParams();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (resetToken) {
      reset.setFieldValue("token", resetToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetToken]);

  return (
    <LayoutOne>
      <Imgsection image="/images/banner33.jpg" title="Reset Password" />
      <div className="py-[20px] pb-[20px] md:pb-[30px] w-[90%] md:w-[50%] m-auto text-teal-700  ">
        <p className=" pb-0 md:pb-[30px] text-[20px] md:text-[26px] font-[600] text-sub_main text-left md:text-center ">
          Reset Your Password
        </p>

        <form onSubmit={reset.handleSubmit} autoComplete="off">
          <FormInput
            error={reset.errors.password!}
            onBlur={reset.handleBlur}
            onChange={reset.handleChange}
            type={showPassword ? "text" : "password"}
            icon={showPassword ? PiEyeLight : PiEyeSlashLight}
            value={reset.values.password}
            className="  "
            name="password"
            onClick={() => {
              showPassword ? setShowPassword(false) : setShowPassword(true);
            }}
          />
          <Link className=" block py-3 text-blue-500 " to={routeObj.forgot}>
            Resend Link
          </Link>
          <div className=" flex justify-end ">
            <Button
              loading={reset.isSubmitting}
              className=" bg-main w-full md:w-[130px] mt-[30px] md:mt-[25px] rounded-xl text-[1rem] md:text-[18px] py-[15px] md:py-[10px] text-white  "
              type="submit"
            >
              Reset
            </Button>
          </div>
        </form>
      </div>
    </LayoutOne>
  );
}
