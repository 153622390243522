import InvestmentFocus from "./InvestmentFocus";
import ButtonSlide from "../../components/Comp_Layouts/ButtonSlide";

export default function Better() {
  return (
    <div>
      <div className="flex flex-col md:flex-row ">
        <div className=" bg-main border-[#ccb884] border-4 md:w-[50%] h-[400px] md:h-[500px] flex flex-col justify-center pl-[40px] text-white ">
          <h1 className="text-[22px] md:text-[32px] ">
            Invest in a better retirement
          </h1>
          <p className=" py-[40px] pr-[10px] text-[14px] md:text-[18px]  ">
            We’re helping to make retirement more accessible. We have answers
            and insights, no matter where you are on your journey. We make
            investing easier and more affordable.
          </p>
          <ButtonSlide title="Learn More" link={""} />
        </div>

        <div
          className=" h-[400px] md:h-[500px] md:w-[50%] bg-center bg-cover bg-no-repeat "
          style={{ backgroundImage: `url('/images/banner1.webp')` }}
        ></div>
      </div>
      <div className="block md:hidden ">
        <InvestmentFocus />
      </div>
      <div className="flex flex-col md:flex-row ">
        <div
          className=" h-[400px] md:h-[500px] md:w-[50%] bg-center bg-cover bg-no-repeat "
          style={{ backgroundImage: `url('/images/gg.png')` }}
        ></div>

        <div className=" bg-main border-[#ccb884] border-4 md:w-[50%] h-[400px] md:h-[500px] flex flex-col justify-center pl-[40px] text-white ">
          <h1 className="text-[22px] md:text-[32px] ">Who Are We</h1>
          <p className=" py-[40px] pr-[10px] text-[14px] md:text-[18px]  ">
            At Ark, we tie our success to yours. Through trust, integrity, and
            tailored solutions, our client-focused team is here to understand
            your goals and create customized solutions to meet your needs.
          </p>
          <ButtonSlide title="Learn More" link={"/"} />
        </div>
      </div>
    </div>
  );
}
