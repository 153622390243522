import { Link, useSearchParams } from "react-router-dom";
import LayoutOne from "../Layout/Layout_One";
import Imgsection from "../components/Comp_Layouts/HeroTwo";
import { routeObj } from "../constants/routes";
import { useEffect, useState } from "react";
import { useRegister } from "../Hooks/authHooks";
import { FormInput, SelectInput } from "../components/Comp_Layouts/Form";
import countries from "../utils/countries.json";
import { Button } from "../components/Button";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";

export default function Register() {
  const [searchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [country, setCountries] = useState<Array<string>>();
  const { register } = useRegister();

  useEffect(() => {
    try {
      setCountries(Object.keys(countries));
    } catch (error) {
      console.log(error as TypeError);
    }
  }, []);

  useEffect(() => {
    const code = searchParams.get("referral");
    if (code) {
      register.setFieldValue("referral", code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <LayoutOne>
      <Imgsection
        image="/images/banner2.jpg"
        sub_title="Join Us"
        title="Sign-up"
      />

      <div className="py-[20px] pb-[20px] md:pb-[30px] w-[90%] md:w-[50%] m-auto text-teal-700  ">
        <p className=" pb-0 md:pb-[30px] text-[20px] md:text-[26px] font-[600] text-sub_main text-left md:text-center ">
          Register With Us
        </p>

        <form onSubmit={register.handleSubmit} autoComplete="off">
          <FormInput
            error={register.errors.fullname!}
            onBlur={register.handleBlur}
            onChange={register.handleChange}
            type="text"
            label="Fullname"
            value={register.values.fullname}
            className=""
            name="fullname"
          />

          <FormInput
            error={register.errors.email!}
            onBlur={register.handleBlur}
            onChange={register.handleChange}
            type="text"
            value={register.values.email}
            className=""
            label="Email"
            name="email"
          />

          <FormInput
            error={register.errors.password!}
            onBlur={register.handleBlur}
            onChange={register.handleChange}
            type={showPassword ? "text" : "password"}
            icon={showPassword ? PiEyeLight : PiEyeSlashLight}
            value={register.values.password}
            className="  "
            name="password"
            onClick={() => {
              showPassword ? setShowPassword(false) : setShowPassword(true);
            }}
          />

          <SelectInput
            error={register.errors.country!}
            onBlur={register.handleBlur}
            onChange={register.handleChange}
            value={register.values.country}
            className=""
            label="Country"
            name="country"
          >
            {country?.map((item, index) => (
              <option
                value={item}
                key={`country_${index}`}
                style={{
                  backgroundColor: "white",
                  color: "black",
                  marginLeft: "10px",
                }}
              >
                {item}
              </option>
            ))}
          </SelectInput>

          <FormInput
            error={register.errors.phone!}
            onBlur={register.handleBlur}
            onChange={register.handleChange}
            type="text"
            label="Phone"
            value={register.values.phone}
            className=""
            name="phone"
          />

          <FormInput
            error={register.errors.referral!}
            onBlur={register.handleBlur}
            onChange={register.handleChange}
            label="Referral"
            type="text"
            value={register.values.referral}
            className=""
            name="referral"
          />

          <div className="mt-5  text-[14px] ">
            Already Registered?
            <Link to={routeObj.login} className=" text-blue-300">
              {" "}
              Login
            </Link>
            <div className="flex gap-4 mt-5 ">
              <input
                type="checkbox"
                onChange={() => {
                  setChecked(!checked);
                }}
                checked={checked}
              />
              <p className=" pt-[3px] text-[13px] md:text-[16px] ">
                I've Read and Agreed To The Terms of Service
              </p>
            </div>
          </div>

          <div className=" flex justify-end ">
            <Button
              loading={register.isSubmitting}
              className=" bg-main w-full md:w-[130px] mt-[30px] md:mt-[25px] rounded-xl text-[1rem] md:text-[18px] py-[15px] md:py-[10px] text-white  "
              type="submit"
            >
              Register
            </Button>
          </div>
        </form>
        <div className=" py-[15px] px-[5px] ">
          {/* <input
            type="checkbox"
            onChange={() => {
              setChecked(!checked);
            }}
            checked={checked}
          >
            <p className=" pt-[3px] text-[13px] md:text-[16px] ">
              I've Read and Agreed To The Terms of Service
            </p>
          </input> */}
        </div>
        {/* <div className=" flex  w-full md:w-[70%] m-auto mt-[10px] pt-[20px] min-h-[50px] justify-between "> */}

        {/* </div> */}
      </div>
    </LayoutOne>
  );
}
