import { useFormik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { EditUser } from "../store/service/auth";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../store/slice/auth";
import { EditCrypto } from "../store/service/adminApi";
import { getCoin } from "../store/service/common";

export const initialEditValues = (values?: {
  fullname: string;
  phone: string;
  address: string;
  country: string;
  crypto: string;
  email: string;
}) => ({
  fullname: values?.fullname || "",
  phone: values?.phone || "",
  address: values?.address || "",
  country: values?.country || "",
  crypto: values?.crypto || "",
  email: values?.email || "",
});

const editProfileSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email"),
  fullname: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  last_name: Yup.string().min(2, "Too Short!").max(50, "Too Long!"),
  phone: Yup.string().min(2, "Too Short!").max(20, "Too Long!"),
});

export const useEditProfile = (user: any) => {
  const dispatch = useDispatch<AppDispatch>();

  const editProfile = useFormik({
    initialValues: initialEditValues(user),
    validationSchema: editProfileSchema,
    onSubmit: async (values) => {
      try {
        const { message, data } = await dispatch(EditUser(values)).unwrap();
        dispatch(setCurrentUser(data));
        return toast.success(message);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return { editProfile };
};

export const useAddress = (crypto: CryptoCrrncy) => {
  const dispatch = useDispatch<AppDispatch>();

  const address = useFormik({
    initialValues: { ...crypto },
    onSubmit: async (values) => {
      try {
        const { message } = await dispatch(EditCrypto(values)).unwrap();
        await dispatch(getCoin());
        return toast.success(message);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return { address };
};
