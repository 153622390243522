import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { updateAccount } from "../store/service/adminApi";
import { useNavigate } from "react-router-dom";
import { routeObj } from "../constants/routes";

export const useUsersAccount = (account?: UsersAccount, id?: string) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const account_user = useFormik({
    initialValues: {
      ...account,
      amount: account?.amount ?? "0",
      total_profit: account?.total_profit ?? "0",
      amt_withdrawn: account?.amt_withdrawn ?? "0",
      profit: account?.profit ?? "0",
      todays_profit: account?.todays_profit ?? "0",
      userId: id,
    },
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const { message } = await dispatch(updateAccount(values)).unwrap();
        navigate(routeObj.all_users);
        setSubmitting(false);
        return toast.success(message);
      } catch (error) {
        toast.error(error as string);
      }
    },
  });

  return { account_user };
};
