import LayoutOne from "../Layout/Layout_One";
import Imgsection from "../components/Comp_Layouts/HeroTwo";
import { inv_equipped } from "../utils/data";
import Management from "./component_sections/Management";
import MoreInvestmentFocus from "./component_sections/MoreInvestment";
import Testimonials from "./component_sections/Testimonials";

export default function Service() {
  const text_query = "text-[14px] md:text-[18px]";
  const hd_query = "text-[20px] md:text-[32px]";

  return (
    <LayoutOne>
      <div className="pb-[20px] md:pb-0">
        <Imgsection image="/images/banner35.jpg" title="Services" />
        <div className="bg-main py-[55px] ">
          <p className={`w-[78%] m-auto text-white ${text_query} `}>
            Welcome to Ark, your trusted partner for comprehensive financial
            solutions and expert hedge fund management. With our years of
            experience and a team of seasoned professionals, we are committed to
            helping you navigate the complexities of the financial world and
            achieve your investment goals.
          </p>
        </div>
        <div
          className={` pt-[50px] ${text_query} flex justify-center items-center flex-col `}
        >
          <hr className="border-4 w-[10%] border-[#ccb884] " />
          <h1 className={` py-[20px] text-sub_main ${hd_query} font-bold`}>
            Our Services
          </h1>
          <p className={` w-[90%] md:w-[80%] ${text_query}`}>
            At Ark, we pride ourselves on our revolutionary "BlackBox"
            algorithm, a proprietary technology that predicts market trends with
            astonishing accuracy. Gain exclusive access to this powerful tool,
            giving you a distinct advantage in the investment world. Our
            algorithm guarantees consistent returns and helps you outperform the
            market effortlessly.
          </p>

          <div className=" h-[200px] md:h-[300px] pt-[50px] w-[90%] md:w-[80%] flex justify-between ">
            <div className=" min-h-[90%] w-[30%] md:w-[15%] flex flex-col items-center ">
              <div className=" h-[50px] w-[50px] border-4 border-[#06274e] rounded-full flex justify-center items-center ">
                <img loading="lazy" src="/images/ico1.png" alt="Exclusive" />
              </div>
              <p className={`text-sub_main ${text_query} `}>
                <b>Exclusive</b>
              </p>
              <p className={`${text_query}`}>Access</p>
            </div>
            <div className=" min-h-[90%] w-[30%] md:w-[15%] flex flex-col items-center ">
              <div className="h-[50px] w-[50px] border-4 border-[#06274e] rounded-full flex justify-center items-center ">
                <img loading="lazy" src="/images/ico2.png" alt="Top-Tier" />
              </div>
              <p className={`text-sub_main ${text_query} `}>
                <b>Top-Tier </b>
              </p>
              <p className={`${text_query}`}>Advisors</p>
            </div>
            <div className=" min-h-[90%] w-[30%] md:w-[15%] flex flex-col items-center ">
              <div className=" h-[50px] w-[50px] border-4 border-[#06274e] rounded-full flex justify-center items-center ">
                <img loading="lazy" src="/images/ico3.png" alt="Guaranteed" />
              </div>
              <p className={`text-sub_main ${text_query} `}>
                <b>Guaranteed </b>
              </p>
              <p className={`${text_query}`}>Returns</p>
            </div>
            <div className=" min-h-[90%] w-[15%] hidden md:flex flex-col items-center ">
              <div className=" h-[50px] w-[50px] border-4 border-[#06274e] rounded-full flex justify-center items-center ">
                <img loading="lazy" src="/images/ico4.png" alt="Pre-IPO" />
              </div>
              <p className={`text-sub_main ${text_query} `}>
                <b>Pre-IPO </b>
              </p>
              <p className={`${text_query}`}>Opportunities</p>
            </div>
            <div className=" min-h-[90%] hidden m w-[15%] md:flex flex-col items-center ">
              <div className=" h-[50px] w-[50px] border-4 border-[#06274e] rounded-full flex justify-center items-center ">
                <img loading="lazy" src="/images/ico5.png" alt="Zero-Risk" />
              </div>
              <p className={`text-sub_main ${text_query} `}>
                <b>Zero-Risk</b>
              </p>
              <p className={`${text_query}`}>Investment</p>
            </div>
          </div>
        </div>
        <Management />
        <Testimonials />
        <MoreInvestmentFocus />

        <div className=" pt-[50px] pb-[70px] flex flex-col items-center justify-between min-h-[500px] ">
          <h1 className=" pb-[20px] text-sub_main w-[80%] text-center font-bold text-[22px] md:text-[27px] ">
            We Remain Equipped To Deliver.
          </h1>
          <div className=" px-[20px] md:px-[0] w-[95%] md:w-[80%] m-auto flex-wrap flex flex-col md:flex-row justify-between text-center text-[14px] md:text-[18px]  ">
            {inv_equipped?.map((equipped, index) => (
              <div key={index} className=" w-full md:w-[30%]">
                <div className=" min-h-[200px] md:min-h-[230px] p-3 shadow-lg rounded-md mt-[40px] md:mt-[50px] pt-[10px] px-[10px] ">
                  <div>
                    <p className=" text-[18px] font-semibold pb-[20px] text-sub_main">
                      <b>{equipped?.heading}</b>
                    </p>
                    <p className=" text-[14px] md:text-[18px] ">
                      {equipped?.desc}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </LayoutOne>
  );
}
