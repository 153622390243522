import { AdminLinkItems, LinkItems } from "./Links";
import { NavLink } from "./NavLink";
import { FiLogOut } from "react-icons/fi";
import { useSelector } from "react-redux";
import { SelectAuth } from "../../store/slice/auth";
import { useLogout } from "../../Hooks/authHooks";

export default function SideBar() {
  const { user } = useSelector(SelectAuth);
  const { Logout } = useLogout();

  // console.log(user);

  return (
    //commented hidden out........a
    <div className="w-[100%] pb-[100px] md:pb-[0]  pt-[100px] text-white hidden  md:flex  bg-main md:w-[18%] border-2 border-[#d9bf84] border-r-0 min-h-[100vh]   ">
      <div className=" w-full overflow-y-scroll  h-[90vh] bg-red-00 ">
        <div className=" pb-6">
          {user && user.type === "ADMIN"
            ? AdminLinkItems?.map((item) => (
                <NavLink key={item.name} {...item} />
              ))
            : LinkItems?.map((item) => <NavLink key={item.name} {...item} />)}
          <button
            onClick={() => {
              Logout();
            }}
            className=" w-full mt-10 md:rounded-tl-3xl md:rounded-bl-3xl py-3  text-sub_main bg-white  text-left flex gap-5 px-5 "
          >
            {" "}
            <div className=" text-[20px] ">
              <FiLogOut fill="#d9bf84" />
            </div>{" "}
            Signout
          </button>
        </div>
      </div>
    </div>
  );
}
