import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import {
  createUpdate,
  getUpdates,
  updateBlog,
} from "../store/service/adminApi";

const initialNotifier = {
  title: "",
  description: "",
};

export const useCreateNotifier = (
  update?: UpdateData | null,
  close?: () => void
) => {
  const dispatch = useDispatch<AppDispatch>();

  const notifier = useFormik({
    initialValues: initialNotifier,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const { message } = await dispatch(createUpdate(values)).unwrap();
        await dispatch(getUpdates(null));
        setSubmitting(false);
        close!();
        return toast.success(message);
      } catch (error) {
        toast.error(error as string);
      }
    },
  });

  const editNotifier = useFormik({
    initialValues: { ...update },
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const { message } = await dispatch(updateBlog(values)).unwrap();
        setSubmitting(false);
        return toast.success(message);
      } catch (error) {
        toast.error(error as string);
      }
    },
  });

  return { notifier, editNotifier };
};
