import { createElement, useEffect } from "react";
import Layout from "../Layout/Layout";
import { dashboardDetails } from "../store/service/common";
import { SelectDashboard } from "../store/slice/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { GiMoneyStack, GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { AiFillProfile } from "react-icons/ai";
import { AppDispatch } from "../store";
import { PuffLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import { SelectAuth } from "../store/slice/auth";
import { numberFormatter } from "../utils/functions";

export default function WithdrawFunds() {
  const dispatch = useDispatch<AppDispatch>();
  const { total, loading } = useSelector(SelectDashboard);
  const { user } = useSelector(SelectAuth);
  const location = useLocation();

  // console.log(user);

  const Info: usersInfo = [
    {
      icon: GiPayMoney,
      title: "Total Deposits",
      data: total.depositTot,
    },
    {
      icon: GiMoneyStack,
      title: "Total Withdrawals",
      data: total.withdrawalTot,
    },
    {
      icon: GiTakeMyMoney,
      title: "Total Earning",
      data: total.profitTot,
    },
    {
      icon: AiFillProfile,
      title: "Total Referrals",
      data: total.referrals,
    },
  ];

  useEffect(() => {
    const init = async () => {
      try {
        await dispatch(dashboardDetails());
      } catch (error) {
        console.log(error);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <Layout>
      <div className="flex flex-col gap-10 md:gap-3 flex-wrap pt-10 md:flex-row justify-between ">
        {Info.map((value, index) => (
          <div
            key={index}
            className=" w-full md:w-[23%] px-5 py-8 rounded-md min-h-[80px] bg-white  "
          >
            {loading && (
              <div className="">
                <PuffLoader size={30} />
              </div>
            )}
            {!loading && (
              <div className="flex gap-5 items-center ">
                <p className=" text-[50px] text-sub_main ">
                  {createElement(value.icon)}
                </p>
                <div className=" dash_webkit flex flex-col  overflow-x-scroll ">
                  <p className="">{value.title}</p>
                  <p className="pt-2 ">
                    {value.title !== "Total Referrals"
                      ? `$${numberFormatter(value.data)}`
                      : value.data}
                  </p>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="mt-10">
        <i>
          An essential protocol to observe is that every withdrawal request
          should be submitted one week following the prior request for the same
          deposit. Requests that fail to adhere to this timing guideline will be
          overlooked and declined.
        </i>
      </div>
      <p className=" mt-10 text-[16px] md:text-[20px] font-[700] ">
        Withdrawal Form
      </p>
      <div className="">
        {user?.account_mode === "BUSINESS" && (
          <div className=" bg-main text-center py-7 px-5 my-6 capitalize font-bold text-white ">
            Account is Currently Set To Company Mode. Please Contact Your
            Financial Advisor For Further Information
          </div>
        )}
        {user?.account_mode === "WEEKLY" && (
          <div className=" bg-main text-center py-7 px-5 my-6 capitalize font-bold text-white ">
            You can't request for withdrawals. Your account is on a Weekly Mode
            of payment. You will recieve your ROI when it is due
          </div>
        )}
      </div>
    </Layout>
  );
}
