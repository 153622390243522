import React from "react";
import LayoutOne from "../Layout/Layout_One";
import Imgsection from "../components/Comp_Layouts/HeroTwo";
import { FormInput } from "../components/Comp_Layouts/Form";
import { useForgot } from "../Hooks/authHooks";
import { Button } from "../components/Button";

export default function ForgotPassword() {
  const { forgot } = useForgot();

  return (
    <LayoutOne>
      <Imgsection image="/images/banner29.jpg" title="Forgot Password" />
      <div className="py-[20px] pb-[20px] md:pb-[30px] w-[90%] md:w-[50%] m-auto text-teal-700  ">
        <p className=" pb-0 md:pb-[30px] text-[20px] md:text-[26px] font-[600] text-sub_main text-left md:text-center ">
          Forgot Password
        </p>

        <form onSubmit={forgot.handleSubmit} autoComplete="off">
          <FormInput
            error={forgot.errors.email!}
            onBlur={forgot.handleBlur}
            onChange={forgot.handleChange}
            type="text"
            value={forgot.values.email}
            className=" "
            name="email"
          />

          <div className=" flex justify-end ">
            <Button
              loading={forgot.isSubmitting}
              className=" bg-main w-full md:w-[130px] mt-[30px] md:mt-[25px] rounded-xl text-[1rem] md:text-[18px] py-[15px] md:py-[10px] text-white  "
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </LayoutOne>
  );
}
