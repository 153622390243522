import { setUpdateActive } from "../../store/slice/update";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";

type Props = {
  description: string;
  title: string;
};

export default function AccountUpdate(props: Props) {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className=" flex justify-center items-center  ">
      <div className="min-h-[50vh]  md:h-[50vh] flex flex-col text-center justify-center items-center  md:w-[50vw] px-6 ">
        <img src="/images/logo.webp" alt="" />
        <p className=" py-3 md:text-[17px] font-bold ">{props.title}</p>
        <p className=" md:text-[17px]">{props.description}</p>
        <button
          className=" bg-main text-white px-4 py-2 my-4 rounded-lg "
          onClick={() => {
            dispatch(setUpdateActive(false));
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
}
