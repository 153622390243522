import { MdDoneAll, MdSettings } from "react-icons/md";

export default function Management() {
  return (
    <div>
      <div className=" bg-[#e5e5e5] pt-[30px] pb-[40px] flex flex-col items-center min-h-[350px] w-full ">
        <div className="flex w-[90%] md:w-[80%] flex-col md:flex-row ">
          <h1 className=" md:w-[40%] pb-[20px] font-bold md:pb-0 text-sub_main transition-all text-[22px] md:text-[27px] ">
            Management Services
          </h1>
          <ul className="space-y-5 md:w-[78%] transition-all ">
            <li className="flex flex-col ">
              <div className="flex gap-3 items-center md:pl-[26px]">
                <MdDoneAll color="bg.subMain" fontSize={"larger"} />
                <p className=" text-[20px] py-[10px] md:py-0 text-main ">
                  <b>Portfolio Management</b>:
                </p>
              </div>
              <p className="md:pl-[26px] text-[15px] py-[10px] md:py-0 ">
                If you prefer a hands-off approach, our portfolio management
                services are designed to meet your needs. Our experienced
                portfolio managers will construct and manage a customized
                investment portfolio based on your risk profile, investment
                objectives, and time horizon. Benefit from our expertise in
                asset allocation, diversification, and active management to
                optimize your returns
              </p>
            </li>

            <li className="flex flex-col ">
              <div className="flex gap-3 items-center md:pl-[26px]">
                <MdDoneAll color="bg.subMain" fontSize={"larger"} />
                <p className=" text-[20px] py-[10px] md:py-0 text-main ">
                  <b>Wealth Management</b>:
                </p>
              </div>
              <p className="md:pl-[26px] text-[15px] py-[10px] md:py-0 ">
                Our wealth management services provide personalized strategies
                for your unique financial goals. Through careful assessment and
                planning, we aim to grow and protect your wealth. Our skilled
                advisors assist with asset allocation, risk management, tax
                planning, and estate planning. Maximize your financial potential
                with our comprehensive approach.
              </p>
            </li>

            <li className="flex flex-col ">
              <div className="flex gap-3 items-center md:pl-[26px]">
                <MdDoneAll color="bg.subMain" fontSize={"larger"} />
                <p className=" text-[20px] py-[10px] md:py-0 text-main ">
                  <b>Hedge Fund Management</b>:
                </p>
              </div>
              <p className="md:pl-[26px] text-[15px] py-[10px] md:py-0 ">
                We are a leading hedge fund management company, delivering
                exceptional risk-adjusted returns through curated portfolios.
                Our experienced team employs rigorous analysis, research, and
                modeling to identify lucrative opportunities. With a focus on
                risk management and diversification, we aim for consistent
                above-average returns for our clients.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className=" bg-white pt-[30px] pb-[40px] flex flex-col items-center min-h-[350px] w-full ">
        <div className="flex w-[90%] md:w-[80%] flex-col md:flex-row ">
          <h1 className=" md:w-[40%] pb-[20px] font-bold md:pb-0 text-sub_main transition-all text-[22px] md:text-[27px] ">
            Planning Services
          </h1>
          <ul className="space-y-5 md:w-[78%] transition-all ">
            <li className="flex flex-col ">
              <div className="flex gap-3 items-center md:pl-[26px]">
                <MdSettings color="bg.subMain" fontSize={"larger"} />
                <p className=" text-[20px] py-[10px] md:py-0 text-main ">
                  <b>Retirement Planning</b>:
                </p>
              </div>
              <p className="md:pl-[26px] text-[15px] py-[10px] md:py-0 ">
                Plan for a secure financial future with our retirement planning
                services. Our expert team evaluates assets, projects income
                needs, and creates comprehensive retirement plans. We provide
                guidance on account management, asset allocation, and income
                distribution strategies. Ensure a comfortable and worry-free
                retirement with our dedicated expertise.
              </p>
            </li>

            <li className="flex flex-col ">
              <div className="flex gap-3 items-center md:pl-[26px]">
                <MdSettings color="bg.subMain" fontSize={"larger"} />
                <p className=" text-[20px] py-[10px] md:py-0 text-main ">
                  <b>Tax Planning and Compliance</b>:
                </p>
              </div>
              <p className="md:pl-[26px] text-[15px] py-[10px] md:py-0 ">
                Simplify tax complexities with our comprehensive tax planning
                and compliance services. Our experts optimize tax efficiency
                while ensuring full compliance with regulations. Stay updated on
                tax laws and discover potential savings opportunities. Minimize
                tax liabilities with strategic implementation.
              </p>
            </li>

            <li className="flex flex-col ">
              <div className="flex gap-3 items-center md:pl-[26px]">
                <MdSettings color="bg.subMain" fontSize={"larger"} />
                <p className=" text-[20px] py-[10px] md:py-0 text-main ">
                  <b>Estate Planning</b>:
                </p>
              </div>
              <p className="md:pl-[26px] text-[15px] py-[10px] md:py-0 ">
                Preserving and transferring your wealth to future generations
                requires careful estate planning. Our estate planning services
                help you develop a robust plan to protect your assets, minimize
                estate taxes, and ensure your legacy is preserved according to
                your wishes. We work closely with legal professionals to create
                trusts, wills, and other estate planning instruments that align
                with your unique circumstances and goals
              </p>
            </li>
          </ul>
        </div>
      </div>

      {/* <Flex
          bg={"white"}
          pt="30px"
          pb="40px"
          flexDir={{ base: "column", md: "column" }}
          // mt="30px"
          align="center"
          minH={"420px"}
          w="100%"
        >
          <Flex
            w={{ base: "90%", md: "80%" }}
            margin=""
            bg={""}
            flexDir={{ base: "column", md: "row" }}
          >
            <Heading
              bg={""}
              w={{ base: "100%", md: "40%" }}
              pb={{ base: "20px", md: "0px" }}
              color="bg.main"
              ref={reference2}
              opacity={inViewoftwo ? 1 : 0}
              transition=" 1.5s"
              // transitionDelay={"0.4s"}
              fontSize={{ base: "20px", md: "25px" }}
            >
              Planning Services
            </Heading>
            <List
              spacing={3}
              w={{ base: "100%", md: "78%" }}
              margin={""}
              py="  0px"
              color={""}
              ref={reference2}
              opacity={inViewoftwo ? 1 : 0}
              transition=" 1.5s"
              // transitionDelay={"0.9s"}
            >
              <ListItem>
                <ListIcon
                  display={{ base: "none", md: "inline-list-item" }}
                  as={MdSettings}
                  color="bg.subMain"
                  fontSize={"larger"}
                />
                <Text as={"span"} fontSize={"20px"} color="bg.subMain">
                  <b>Retirement Planning</b>:
                </Text>
                <Text
                  pl={{ base: "0", md: "26px" }}
                  fontSize={{ base: "", md: "14px" }}
                  py={{ base: "10px", md: "0px" }}
                >
                  Plan for a secure financial future with our retirement planning
                  services. Our expert team evaluates assets, projects income
                  needs, and creates comprehensive retirement plans. We provide
                  guidance on account management, asset allocation, and income
                  distribution strategies. Ensure a comfortable and worry-free
                  retirement with our dedicated expertise.
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon
                  display={{ base: "none", md: "inline-list-item" }}
                  as={MdSettings}
                  color="bg.subMain"
                  fontSize={"larger"}
                />
                <Text as={"span"} fontSize={"20px"} color="bg.subMain">
                  <b>Tax Planning and Compliance</b>:
                </Text>
                <Text
                  pl={{ base: "0", md: "26px" }}
                  fontSize={{ base: "", md: "14px" }}
                  py={{ base: "10px", md: "0px" }}
                >
                  Simplify tax complexities with our comprehensive tax planning
                  and compliance services. Our experts optimize tax efficiency
                  while ensuring full compliance with regulations. Stay updated on
                  tax laws and discover potential savings opportunities. Minimize
                  tax liabilities with strategic implementation.
                </Text>
              </ListItem>
              <ListItem>
                <ListIcon
                  display={{ base: "none", md: "inline-list-item" }}
                  as={MdSettings}
                  color="bg.subMain"
                  fontSize={"larger"}
                />
                <Text as={"span"} fontSize={"20px"} color="bg.subMain">
                  <b>Estate Planning</b>:
                </Text>
                <Text
                  pl={{ base: "0", md: "26px" }}
                  fontSize={{ base: "", md: "14px" }}
                  py={{ base: "10px", md: "0px" }}
                >
                  Preserving and transferring your wealth to future generations
                  requires careful estate planning. Our estate planning services
                  help you develop a robust plan to protect your assets, minimize
                  estate taxes, and ensure your legacy is preserved according to
                  your wishes. We work closely with legal professionals to create
                  trusts, wills, and other estate planning instruments that align
                  with your unique circumstances and goals
                </Text>
              </ListItem>
            </List>
          </Flex>
        </Flex> */}
    </div>
  );
}
