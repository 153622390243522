import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { getUser } from "../store/service/auth";
import { setCurrentUser } from "../store/slice/auth";

export const useGetUser = () => {
  const dispatch = useDispatch<AppDispatch>();

  const FetchUser = async () => {
    try {
      const { data } = await dispatch(getUser()).unwrap();
      console.log(data);
      dispatch(setCurrentUser(data));
    } catch (error) {
      console.log(error);
    }
  };

  return { FetchUser };
};
