import React from "react";
import ColouredButton from "../../components/Comp_Layouts/ColouredButtons";
import { inv_focus } from "../../utils/data";

export default function InvestmentFocus() {
  return (
    <div className="pb-10 bg-red-00">
      <h1 className=" pb-[20px] text-[20px] md:text-[30px] text-center text-sub_main pt-[15px] ">
        Our Investment Focus
      </h1>
      <div className=" pt-[20px] bg-blue-00 px-3 flex-col  ">
        {inv_focus?.map((focus, index) => (
          <div
            key={index}
            className=" flex mt-3 flex-col md:flex-row justify-between  "
          >
            <div className="w-[6%] hidden md:flex text-sub_main justify-center items-center text-[40px] ">
              {focus.icon && React.createElement(focus.icon)}
            </div>
            <div className="md:w-[80%] h-full flex-col justify-center md:justify-end items-center md:items-start ">
              <h1 className=" text-center md:text-left text-[17px] md:text-[20px] text-sub_main">
                {focus.heading}
              </h1>
              <p className=" pt-[5px] text-center md:text-left text-[14px] md:text-[18px] ">
                {focus?.desc}
              </p>
            </div>
            <div className="md:w-fit h-fit flex justify-center items-center ">
              <ColouredButton link={""} title="Learn More" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
