import { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { FormInput, TextAreaInput } from "../../components/Comp_Layouts/Form";
import { Button } from "../../components/Button";
import { useCreateBlog } from "../../Hooks/Blog";
import { useParams } from "react-router-dom";
import { ViewBlog } from "../../store/service/adminApi";

export default function EditBlog() {
  const [select, setSelected] = useState<File | null>(null);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const { editBlogs } = useCreateBlog(select, data!);

  const FetchData = async (id: string) => {
    const { data } = await ViewBlog(id);
    setData(data);
  };

  useEffect(() => {
    if (id) {
      FetchData(id);
    }
  }, [id]);

  return (
    <Layout>
      <div className=" text-[20px] font-bold ">Blog</div>
      <form onSubmit={editBlogs.handleSubmit}>
        <div className=" mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
          <FormInput
            name="title"
            className=""
            label="Title"
            margin={1}
            type="text"
            error={editBlogs.errors.title}
            onBlur={editBlogs.handleBlur}
            onChange={editBlogs.handleChange}
            value={editBlogs.values.title}
          />
          <div className=" flex items-cente flex-col h-fit ">
            <p className="pb-3">Image</p>
            <div className="bg-ubmain py-3 px-3 w-full ">
              <input
                type="file"
                accept="image/png,image/jpeg,image/jpg,image/gif,image/webp,image/tiff"
                onChange={(e) => {
                  if (e.target.files) {
                    setSelected(e.target.files[0]);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className=" mt-10 ">
          <TextAreaInput
            name="description"
            className=""
            label="Description"
            margin={1}
            type="text"
            error={editBlogs.errors.description!}
            onBlur={editBlogs.handleBlur}
            onChange={editBlogs.handleChange}
            value={editBlogs.values.description}
          />
        </div>
        <div className="  mt-10 w-full gap-5 bg-red-00 flex justify-end ">
          <Button
            loading={editBlogs.isSubmitting}
            className="py-3 w-full md:w-[200px] px-10 bg-main text-white rounded-lg"
            type="submit"
          >
            Update
          </Button>
        </div>
      </form>
    </Layout>
  );
}
