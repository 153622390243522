import { motion } from "framer-motion";
import { inv_principles } from "../../utils/data";
import React from "react";
import MotionBox from "../../components/Motion";

export default function Principles() {
  return (
    <div
      className=" min-h-[150vh] bg-center bg-cover bg-no-repeat relative flex flex-col "
      style={{ backgroundImage: `url('/images/ski2.jpg')` }}
    >
      <div className="bg-rg pt-[30px] pb-[70px] flex flex-col items-center justify-between min-h-[300px] ">
        <p className=" pb-[20px] text-[23px] md:text-[32px] font-bold ">
          key principles and approaches
        </p>
        <div className=" flex px-[20px] md:px-[50px] w-[98%] md:w-[85%] m-auto flex-wrap flex-col md:flex-row justify-between text-center text-[14px] md:text-[18px] ">
          {inv_principles?.map((value, index) => (
            <div
              key={`principles-${index}`}
              className="flex duration-200 mt-5 md:w-[46%]"
            >
              <MotionBox>
                <motion.div whileHover={{ scale: 1.1 }}>
                  <div className=" min-h-[280px] md:min-h-[300px] bg-rgba  pr-3 shadow-lg rounded-md mt-40px md:mt-[50px] py-[10px] px-[5px] relative">
                    <div className="text-sub_main">
                      <h1 className=" flex justify-center items-center py-[20px] text-[40px] text-center ">
                        {value.icon && React.createElement(value.icon)}
                      </h1>{" "}
                      <p className=" text-[16px] font-[500] pb-[20px] text-center ">
                        <b>{value?.heading}</b>
                      </p>
                      <p>{value?.desc}</p>
                    </div>
                  </div>
                </motion.div>
              </MotionBox>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
