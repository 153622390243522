import LayoutOne from "../Layout/Layout_One";
import Imgsection from "../components/Comp_Layouts/HeroTwo";

export default function Security() {
  const text_query = "text-[14px] md:text-[18px]";

  return (
    <LayoutOne>
      <Imgsection image="/images/banner32.jpg" title="Security" />
      <div className=" bg-[#FBF9F9] pt-[50px]">
        <p className="text-center md:w-[40%] m-auto text-main font-bold text-[18px] md:text-[32px] ">
          AVOIDING ONLINE SCAMS IN THE DIGITAL AGE{" "}
        </p>
        <p className={`text-center px-3 m-auto pt-[10px] ${text_query} `}>
          Remain watchful to safeguard your personal information and financial
          details. Here's what you need to know.
        </p>
        <p className={`text-center m-auto w-[90%] md:w-[80%] ${text_query}`}>
          Instances of online scams continue to emerge at an alarming rate.
          While the internet has undeniably brought numerous positive changes to
          the world, there is also a downside. Online scams remain widespread,
          and contrary to popular belief that they primarily target senior
          citizens, a recent study conducted by the Federal Trade Commission
          (FTC) reveals that more millennials than retirees are falling victim
          to online fraud, losing their hard-earned money in the process. It is
          important for everyone to exercise caution as these scams can affect
          anyone. In the year 2020, California alone has reported over 3,500
          cases of identity theft, underscoring the seriousness of the issue.
        </p>
        <p className={`w-[90%] md:w-[80%] m-auto pt-[20px]  ${text_query}`}>
          At Ark Investment Financial management, protecting your money and
          personal information is a top priority. That’s why we’ve outlined how
          to spot the latest online scams and how you can protect yourself with
          these internet safety tips.
        </p>
        <div className={` py-[40px] m-auto w-[90%] md:w-[80%] ${text_query}`}>
          <h1 className=" text-[18px] md:text-[30px] text-sub_main ">
            FACEBOOK SCAMS
          </h1>
          <p className="pt-[20px]">
            The Better Business Bureau warns about online fraud happening within
            Facebook. It starts with a “friend or relative” who contacts you
            through Facebook saying you are entitled to free money. These can
            come from fake profiles or hacked accounts. The catch? You need to
            pay up front for shipping or processing or provide other sensitive
            information that can be used for identity theft.
          </p>
          <p className=" pt-[20px] font-[600] text-sub_main">
            Follow these tips to avoid a Facebook hack or scam:
          </p>
          <p className="pt-[20px]">
            <p>
              Don’t give out your password (and don’t use the same password for
              multiple accounts)
            </p>
            <p>Avoid accepting unknown friend requests</p>
            <p>
              Use a secured network, not public Wi-Fi, when signing into any
              accounts (especially your bank account)
            </p>
            <p> Keep apps, browsers and antivirus software updated</p>
          </p>
          <h1 className="text-[18px] md:text-[30px] pt-[20px] text-sub_main">
            SCAM WEBSITES
          </h1>
          <p className="pt-[20px]">
            The old phrase “If it seems too good to be true, it probably is”
            certainly applies to shopping online. Fake shopping websites have
            been popping up lately, aiming to scam you out of your hard-earned
            money. Pay attention to these red flags, which will help tip you off
            to a fake website or online store:
          </p>

          <p className="pt-[20px]">
            <p>Bad English and/or poor website design</p>
            <p>Super-low prices that seem too good to be true</p>
            <p>
              Bootleg logo, store name and/or URL (e.g.
              “you-pay-less-4-mac-stuff.com” or a site with a URL that’s one or
              two letters off from a legitimate domain)
            </p>
            <p> Inability to accept credit or debit card payments</p>
            <p> Reviews that sound suspicious or inauthentic</p>
            <p className="pt-[20px] font-[600] text-sub_main">
              How can you protect yourself from fake shopping websites?
            </p>
            <p className=" pt-[20px] ">
              <p>
                If you are wary of a fake site, run it through Google’s
                Transparency tool or the BBB’s Scam Tracker
              </p>
              <p>Only purchase items online using a secured network</p>
              <p>
                Check to be sure the web address begins with “https,” indicating
                your personal and payment information is encrypted
              </p>
              <p> Use two-factor authentication for paying online</p>
            </p>
            <p className="text-[18px] md:text-[30px] pt-[20px] text-sub_main">
              TEXTING SCAMS, OR “SMISHING”
            </p>
            <p className=" pt-[20px] ">
              You’re likely familiar with phishing scams—fake emails that look
              to be sent from legitimate companies—but have you heard of a
              similar tactic called smishing? Smishing scams involve fraudulent
              text messages that seem urgent and indicate something is wrong.
              These messages prompt you to click a link, send sensitive personal
              information or reply to the text to resolve a serious situation.
              They may also promise free gifts or offers in exchange for
              personal information. So how should you handle a text message you
              think may be spam?
            </p>

            <p className=" pt-[20px] ">
              <p>Don’t reply or click any links</p>
              <p>Delete the text</p>
              <p>
                Use a secured network, not public Wi-Fi, when signing into any
                accounts (especially your bank account)
              </p>
              <p> Keep apps, browsers and antivirus software updated</p>
            </p>
          </p>
        </div>
        <div className={`flex flex-col bg-main py-[55px] ${text_query} `}>
          <p className="w-[78%] m-auto text-white ">
            When it comes to protecting yourself online, knowledge is power.
            Feel free to contact us with any questions.
          </p>
        </div>
      </div>
    </LayoutOne>
  );
}
