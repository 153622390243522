import React from "react";
import { inv_more_focus } from "../../utils/data";

export default function MoreInvestmentFocus() {
  return (
    <div className="pb-10 bg-red-00">
      <h1 className=" md:pb-[20px] text-[20px] font-bold md:text-[30px] text-center text-sub_main pt-[15px] ">
        More On Our Investment Focus
      </h1>
      <div className=" pt-[0px]  bg-blue-00 px-3 flex-col  ">
        {inv_more_focus?.map((focus, index) => (
          <div
            key={index}
            className=" flex mt-8 shadow-xl md:shadow-none p-5 md:p-0 rounded-xl flex-col md:flex-row justify-between  "
          >
            <div className="w-[6%] hidden md:flex justify-center items-center text-[40px] ">
              {focus.icon && React.createElement(focus.icon)}
            </div>
            <div className="md:w-[90%] h-full flex-col justify-center md:justify-end items-center md:items-start ">
              <h1 className=" text-center md:text-left text-[17px] font-semibold md:text-[20px] text-sub_main">
                {focus.heading}
              </h1>
              <p className=" pt-[5px] text-center md:text-left text-[14px] md:text-[18px] ">
                {focus?.desc}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
