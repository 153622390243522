import { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import { useNavigate, useParams } from "react-router";
import PayupModal from "./components/PayupModal";
import { AnimatePresence } from "framer-motion";
import ModalP from "../components/modal/ModalP";

export default function DepositType() {
  const navigate = useNavigate();
  const [network, setNetwork] = useState<any>("");
  const [modalOpen, setModalOpen] = useState(false);
  const { type } = useParams<{ type?: string }>();
  const OpenModal = () => setModalOpen(true);
  const CloseModal = () => setModalOpen(false);

  useEffect(() => {
    if (network) {
      OpenModal();
    }
  }, [network]);

  const handleChange = (e: any) => {
    setNetwork(e.target.value);
  };

  return (
    <Layout>
      {modalOpen && (
        <AnimatePresence
          initial={false}
          mode="wait"
          onExitComplete={() => null}
        >
          <ModalP modalOpen={modalOpen} handleClose={CloseModal}>
            <div className="  text-black w-full  ">
              <PayupModal
                network={network}
                plan={type!}
                closeModal={CloseModal}
              />
            </div>
          </ModalP>
        </AnimatePresence>
      )}

      <div>
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </button>
        <div className=" mt-10 md:mt-20 m-auto w-[100%] md:w-[70%] p-4 bg-white ">
          <p className=" text-[25px] pb-2 font-[800] ">Select Network</p>
          <i className="text-[12px] md:text-[16px] ">
            Choose Crypto Network To Make Payment With
          </i>
          <div className=" text-[12px] md:text-[16px]  flex gap-10 pt-5 flex-col  ">
            <label className="flex items-center gap-7 ">
              <input
                value="Bitcoin"
                type="radio"
                checked={network === "Bitcoin"}
                onChange={handleChange}
              />

              <div className="flex items-center gap-3 h-[50px] ">
                <img
                  loading="lazy"
                  alt=""
                  src="/images/bit.png"
                  className="h-[60%]"
                />{" "}
                Bitcoin{" "}
              </div>
            </label>
            <label className="flex items-center gap-4 ">
              <input
                value="Ethereum"
                type="radio"
                checked={network === "Ethereum"}
                onChange={handleChange}
              />
              <div className="flex items-center gap-3 h-[50px] ">
                <img
                  loading="lazy"
                  alt=""
                  src="/images/ether.png"
                  className="h-[60%]"
                />{" "}
                Ethereum Main Network{" "}
              </div>
            </label>
            <label className="flex items-center gap-7 ">
              <input
                value="xrp"
                checked={network === "xrp"}
                type="radio"
                onChange={handleChange}
              />
              <div className="flex items-center gap-3 h-[50px] ">
                <img
                  loading="lazy"
                  alt=""
                  src="/images/xrp.jpeg"
                  className="h-[60%]"
                />{" "}
                XRP
              </div>
            </label>
            <label className="flex items-center gap-7 ">
              <input
                value="usdt"
                checked={network === "usdt"}
                type="radio"
                onChange={handleChange}
              />
              <div className="flex items-center gap-3 h-[50px] ">
                <img
                  loading="lazy"
                  alt=""
                  src="/images/tet.png"
                  className="h-[60%]"
                />{" "}
                Tether Usd
              </div>
            </label>
          </div>
        </div>
      </div>
    </Layout>
  );
}
