import { AiFillProfile } from "react-icons/ai";
import { BiGlobe, BiMessage } from "react-icons/bi";
import { BsFillBookmarkFill } from "react-icons/bs";
import {
  FaAudible,
  FaBitcoin,
  FaBlog,
  FaBuffer,
  FaCoins,
  FaCreativeCommonsPdAlt,
  FaHandshake,
  FaUbuntu,
  FaUserFriends,
  FaUserTie,
} from "react-icons/fa";
import { FiActivity, FiBell } from "react-icons/fi";
import { GiMoneyStack, GiPayMoney, GiReceiveMoney } from "react-icons/gi";

export const LinkItems = [
  {
    name: "Dashboard",
    icon: BsFillBookmarkFill,
    url: "/dashboard",
    status: true,
  },
  {
    name: "Deposit Funds",
    icon: FaCoins,
    url: "/dashboard/deposit",
    status: true,
  },
  {
    name: "View Deposits",
    icon: GiPayMoney,
    url: "/dashboard/view-deposit",
    status: true,
  },
  {
    name: "Withdraw Funds",
    icon: GiReceiveMoney,
    url: "/dashboard/withdrawal-funds",
    status: true,
  },
  {
    name: "View Withdrawals",
    icon: GiMoneyStack,
    url: "/dashboard/view-withdrawal",
    status: true,
  },
  // {
  //   name: "Joint Account",
  //   icon: FaHandshake,
  //   url: "",
  //   status: false,
  //     status: user?.joint_id ? true : false,
  // },
  {
    name: "My Referrals",
    icon: AiFillProfile,
    url: "/dashboard/referrals",
    status: true,
  },
  {
    name: "Profile",
    icon: FaUserTie,
    url: "/dashboard/profile",
    status: true,
  },
  {
    name: "Notifications",
    icon: FiBell,
    url: "/dashboard/notifications",
    status: true,
  },
];

export const AdminLinkItems = [
  {
    name: "Dashboard",
    icon: BsFillBookmarkFill,
    url: "/admin/dashboard",
    status: true,
  },

  {
    name: "All Users",
    icon: FaUserFriends,
    url: "/dashboard/admin/users",
    status: true,
  },
  {
    name: "All Deposits",
    icon: FaBuffer,
    url: "/dashboard/admin/deposits",
    status: true,
  },
  {
    name: "All Referrals",
    icon: FaUbuntu,
    url: "/dashboard/admin/referrals",
    status: true,
  },
  {
    name: "Withdrawal Request",
    icon: FaAudible,
    url: "/dashboard/admin/withdrawals-requests",
    status: true,
  },
  // {
  //   name: "Withdrawal For Joint ",
  //   icon: FaAudible,
  //   url: "/dashboard/admin/joint-withdrawals",
  //   status: true,
  // },
  // {
  //   name: "Joint Account",
  //   icon: FaHandshake,
  //   url: "",
  //   status: true,
  //   //   status: user?.joint_id ? true : false,
  // },
  {
    name: "All Withdrawals",
    icon: FaCreativeCommonsPdAlt,
    url: "/dashboard/admin/withdrawals",
    status: true,
  },
  {
    name: "All Blogs",
    icon: BiGlobe,
    url: "/dashboard/admin/blogs",
    status: true,
  },
  {
    name: "Create Blog",
    icon: FaBlog,
    url: "/dashboard/admin/blogs/create",
    status: true,
  },
  {
    name: "Create Updates",
    icon: BiMessage,
    url: "/dashboard/admin/updates",
    status: true,
  },

  {
    name: "Crypto Address",
    icon: FaBitcoin,
    url: "/dashboard/admin/address",
    status: true,
  },
  {
    name: "Profile",
    icon: FaUserTie,
    url: "/dashboard/admin/profile",
    status: true,
  },
  {
    name: "Activities",
    icon: FiActivity,
    url: "/dashboard/admin/activity",
    status: true,
  },
  {
    name: "Notifications",
    icon: FiBell,
    url: "/dashboard/admin/notifications",
    status: true,
  },
];
