export const NavUrls = [
  {
    id: 1,
    name: "Home",
    link: "/",
    auth: false,
  },
  {
    id: 2,
    name: "Security",
    link: "/security",
    auth: false,
  },
  {
    id: 3,
    name: "Packages",
    link: "/packages",
    auth: false,
  },
  {
    id: 4,
    name: "Services",
    link: "/services",
    auth: false,
  },
  {
    id: 5,
    name: "FAQs",
    link: "/faqs",
    auth: false,
  },
  {
    id: 6,
    name: "Contact",
    link: "/contact",
    auth: false,
  },
  {
    id: 7,
    name: "About Us",
    link: "/about",
    auth: false,
  },
  {
    id: 8,
    name: "Register",
    link: "/register",
    auth: true,
  },
  {
    id: 9,
    name: "Login",
    link: "/login",
    ath: true,
  },
];
