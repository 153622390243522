import React from "react";
import Layout from "../Layout/Layout";
import { inv_types } from "../utils/data";
import { useNavigate } from "react-router";
import { routeObj } from "../constants/routes";

export default function DepositOptions() {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className=" flex flex-wrap justify-between gap-10 ">
        {inv_types?.map((inv, index) => (
          <div key={index} className=" w-[90%] m-auto md:w-[30%] bg-red-00 ">
            <div className=" h-[200px] flex justify-center items-center bg-main rounded-tr-lg rounded-tl-lg ">
              <img className=" h-[60%] " src={inv.picture} alt=" " />
            </div>
            <div className=" border-2  border-[#728191] ">
              <p className=" text-center py-3 border-b-2 border-[#728191] text-[20px] font-[700]  ">
                {inv.plan} Plan
              </p>
              <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
                Minimum Amount : {inv.min}
              </p>
              <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
                Maximum Amount : {inv.max}
              </p>
              <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
                Referral : {inv.ref}
              </p>
              <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
                Weekly ROI : {inv.weekly}
              </p>
              <div className=" bg-red-00 flex items-center text-center py-4 border-b-2 border-[#728191] text-[16px] ">
                <button
                  className=" m-auto py-4 px-10 rounded-xl text-white bg-main"
                  onClick={() => {
                    navigate(`${routeObj.deposit_type}${inv.plan}`);
                  }}
                >
                  Deposit
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  );
}
