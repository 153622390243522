import { Link, useLocation } from "react-router-dom";

interface NavItemProps {
  name: string;
  link: string;
}

export const NavLink = ({ link, name }: NavItemProps) => {
  const pathname = useLocation().pathname;

  return (
    <Link to={link}>
      <div
        className={`w-fit text-sub_main ${
          pathname === link
            ? "font-extrabold text-[20px] md:text-[18px] ml-5 md:ml-0 "
            : ""
        }`}
      >
        {name}
      </div>
    </Link>
  );
};
