// static and auth pages
import Landing from "../StaticPages/Landing";
import Login from "../AuthPages/Login";
import Security from "../StaticPages/Security";
import Packages from "../StaticPages/Packages";
import Service from "../StaticPages/Service";
import Faqs from "../StaticPages/Faqs";
import Contact from "../StaticPages/Contact";
import About from "../StaticPages/About";
import Register from "../AuthPages/Register";
import ForgotPassword from "../AuthPages/ForgotPassword";
import ResetPassword from "../AuthPages/ResetPassword";
import UsersDashboard from "../ProtectedPages/UsersDashboard";
import DepositOptions from "../ProtectedPages/DepositOptions";
import DepositType from "../ProtectedPages/DepositType";
import ViewDeposits from "../ProtectedPages/ViewDeposits";
import ViewWithdrawals from "../ProtectedPages/ViewWithdrawals";
import WithdrawFunds from "../ProtectedPages/WithdrawFunds";
import Referral from "../ProtectedPages/Referral";
import Profile from "../ProtectedPages/Profile";
import EditProfile from "../ProtectedPages/EditProfile";
import AllUsers from "../ProtectedPages/Admin/AllUsers";
import { routeList } from "../types/constants";
import AdminDashboard from "../ProtectedPages/Admin/Dashboard";
import UsersProfit from "../ProtectedPages/Admin/UsersProfit";
import AllDeposits from "../ProtectedPages/Admin/AllDeposits";
import JointDeposits from "../ProtectedPages/Admin/JointDeposits";
import JointWithdrawal from "../ProtectedPages/Admin/JointWithdrawal";
import WithdrawalRequests from "../ProtectedPages/Admin/WithdrawalReq";
import AllWIthdrawals from "../ProtectedPages/Admin/AllWithdrawal";
import AllBlogs from "../ProtectedPages/Admin/AllBlogs";
import Address from "../ProtectedPages/Admin/Address";
import Notifications from "../ProtectedPages/Notifications";
import CreateBlog from "../ProtectedPages/Admin/CreateBlog";
import EditBlog from "../ProtectedPages/Admin/EditBlog";
import EditAccount from "../ProtectedPages/Admin/EditAccount";
import AllUpdates from "../ProtectedPages/Admin/AllUpdates";
import AdminsNotifications from "../ProtectedPages/Admin/Notifications";
import Activity from "../ProtectedPages/Admin/Activity";
import Terms from "../StaticPages/Terms";
import Invoice from "../StaticPages/Invoice";
import AllReferrals from "../ProtectedPages/Admin/AllReferrals";

export const routeObj = {
  landing: "/",
  login: "/login",
  register: "/register",
  security: "/security",
  terms: "/terms",
  packages: "/packages",
  service: "/services",
  faqs: "/faqs",
  contact: "/contact",
  about: "/about",
  invoice: "/invoice",
  forgot: "/forgot-password",
  reset: "/auth/reset-password/",
  users_dashboard: "/dashboard",
  admins_dashboard: "/admin/dashboard",
  deposit_options: "/dashboard/deposit",
  notification: "/dashboard/notifications",
  deposit_type: "/dashboard/deposit/",
  view_deposit: "/dashboard/view-deposit",
  view_withdrawal: "/dashboard/view-withdrawal",
  view_funds: "/dashboard/withdrawal-funds",
  referrals: "/dashboard/referrals",
  profile: "/dashboard/profile",
  edit_profile: "/dashboard/profile/edit",
  all_users: "/dashboard/admin/users",
  usersprofit: "/users-profit/",
  all_deposits: "/dashboard/admin/deposits",
  all_referrals: "/dashboard/admin/referrals",
  all_joint_deposits: "/dashboard/admin/joint-deposits",
  all_joint_withdrawals: "/dashboard/admin/joint-withdrawals",
  all_withdrawals_req: "/dashboard/admin/withdrawals-requests",
  all_withdrawals: "/dashboard/admin/withdrawals",
  all_blogs: "/dashboard/admin/blogs",
  all_activities: "/dashboard/admin/activity",
  create_blogs: "/dashboard/admin/blogs/create",
  edit_blog: "/dashboard/admin/blogs/edit/",
  edit_account: "/dashboard/admin/account/edit/",
  address: "/dashboard/admin/address",
  admin_profile: "/dashboard/admin/profile",
  admin_updates: "/dashboard/admin/updates",
  admin_notifications: "/dashboard/admin/notifications",
};

export const homeRoute: routeList = [
  {
    path: routeObj.landing,
    component: Landing,
  },
  {
    path: routeObj.security,
    component: Security,
  },
  {
    path: routeObj.packages,
    component: Packages,
  },
  {
    path: routeObj.service,
    component: Service,
  },
  {
    path: routeObj.faqs,
    component: Faqs,
  },
  {
    path: routeObj.contact,
    component: Contact,
  },
  {
    path: routeObj.about,
    component: About,
  },
  {
    path: routeObj.terms,
    component: Terms,
  },
  {
    path: routeObj.invoice,
    component: Invoice,
  },
];

export const authRoute: routeList = [
  {
    path: routeObj.login,
    component: Login,
  },
  {
    path: routeObj.register,
    component: Register,
  },
  {
    path: `${routeObj.reset}:reset`,
    component: ResetPassword,
  },
  {
    path: routeObj.forgot,
    component: ForgotPassword,
  },
];

export const protectedRoute: routeList = [
  {
    path: routeObj.users_dashboard,
    component: UsersDashboard,
  },
  {
    path: routeObj.deposit_options,
    component: DepositOptions,
  },
  {
    path: `${routeObj.deposit_type}:type`,
    component: DepositType,
  },
  {
    path: `${routeObj.view_deposit}`,
    component: ViewDeposits,
  },
  {
    path: `${routeObj.view_withdrawal}`,
    component: ViewWithdrawals,
  },
  {
    path: `${routeObj.view_funds}`,
    component: WithdrawFunds,
  },
  {
    path: `${routeObj.referrals}`,
    component: Referral,
  },
  {
    path: `${routeObj.profile}`,
    component: Profile,
  },
  {
    path: `${routeObj.notification}`,
    component: Notifications,
  },

  {
    path: `${routeObj.edit_profile}`,
    component: EditProfile,
  },

  {
    path: `${routeObj.all_users}`,
    component: AllUsers,
  },
  {
    path: `${routeObj.admins_dashboard}`,
    component: AdminDashboard,
  },
  {
    path: `${routeObj.usersprofit}:code`,
    component: UsersProfit,
  },
  {
    path: `${routeObj.all_deposits}`,
    component: AllDeposits,
  },
  {
    path: `${routeObj.all_joint_deposits}`,
    component: JointDeposits,
  },
  {
    path: `${routeObj.all_joint_withdrawals}`,
    component: JointWithdrawal,
  },
  {
    path: `${routeObj.all_withdrawals_req}`,
    component: WithdrawalRequests,
  },
  {
    path: `${routeObj.all_withdrawals}`,
    component: AllWIthdrawals,
  },
  {
    path: `${routeObj.all_blogs}`,
    component: AllBlogs,
  },
  {
    path: `${routeObj.create_blogs}`,
    component: CreateBlog,
  },
  {
    path: `${routeObj.address}`,
    component: Address,
  },
  {
    path: `${routeObj.admin_notifications}`,
    component: AdminsNotifications,
  },
  {
    path: `${routeObj.admin_profile}`,
    component: Profile,
  },
  {
    path: `${routeObj.admin_updates}`,
    component: AllUpdates,
  },
  {
    path: `${routeObj.all_referrals}`,
    component: AllReferrals,
  },
  {
    path: `${routeObj.all_activities}`,
    component: Activity,
  },
  {
    path: `${routeObj.edit_blog}:id`,
    component: EditBlog,
  },
  {
    path: `${routeObj.edit_account}:id`,
    component: EditAccount,
  },
];
