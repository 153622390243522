import { useState } from "react";
import Layout from "../../Layout/Layout";
import { FormInput, TextAreaInput } from "../../components/Comp_Layouts/Form";
import { Button } from "../../components/Button";
import { useCreateBlog } from "../../Hooks/Blog";

export default function CreateBlog() {
  const [select, setSelected] = useState<File | null>(null);
  const { blog } = useCreateBlog(select);

  return (
    <Layout>
      <div className=" text-[20px] font-bold ">Blog</div>
      <form onSubmit={blog.handleSubmit}>
        <div className=" mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
          <FormInput
            name="title"
            className=""
            label="Title"
            margin={1}
            type="text"
            error={blog.errors.title}
            onBlur={blog.handleBlur}
            onChange={blog.handleChange}
            value={blog.values.title}
          />
          <div className=" flex items-cente flex-col h-fit ">
            <p className="pb-3">Image</p>
            <div className="bg-ubmain py-3 px-3 w-full ">
              <input
                type="file"
                onChange={(e) => {
                  if (e.target.files) {
                    setSelected(e.target.files[0]);
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className=" mt-10 ">
          <TextAreaInput
            name="description"
            className=""
            label="Description"
            margin={1}
            type="text"
            error={blog.errors.description!}
            onBlur={blog.handleBlur}
            onChange={blog.handleChange}
            value={blog.values.description}
          />
        </div>
        <div className="  mt-10 w-full gap-5 bg-red-00 flex justify-end ">
          <Button
            loading={blog.isSubmitting}
            className="py-3 w-full md:w-[200px] px-10 bg-main text-white rounded-lg"
            type="submit"
          >
            Update
          </Button>
        </div>
      </form>
    </Layout>
  );
}
