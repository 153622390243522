import { FaBitcoin, FaTrademark } from "react-icons/fa";
import { GiFamilyHouse, GiOilPump } from "react-icons/gi";
import { IoMdFlash } from "react-icons/io";
import { SiMarketo } from "react-icons/si";
import { GiCash, GiTwoCoins } from "react-icons/gi";
import { AiTwotoneAccountBook, AiOutlineBank } from "react-icons/ai";
import { FiLayers } from "react-icons/fi";
import { MdDoneAll } from "react-icons/md";

export const inv_focus = [
  {
    icon: FaBitcoin,
    heading: "Cryptocurrency",
    desc: "Today cryptocurrencies have become known to most people as a conventional and popular investment option. The main purpose of this new technology is to allow people to buy, trade, and invest without the need for banks or any other financial institution. Cryptocurrencies are highly volatile and can be profitable to any trader's portfolio.",
  },
  {
    icon: SiMarketo,
    heading: "Index market",
    desc: "Our Expertise in Index Fund Trading remains topnotch. Our team of experienced investment professionals possesses in-depth knowledge of the index fund market. We stay updated with the latest market trends, economic indicators, and investment strategies, allowing us to make informed trading decisions on behalf of you, our client ",
  },
  {
    icon: GiOilPump,
    heading: "Oil & gas",
    desc: "At the present time CFDs are undoubtedly the most appropriate tools for investing your money in the oil price. They are in fact ‘Contracts for the Difference’ that are available online through broker trading platforms and enable individuals to speculate on the price per barrel of WTI or Brent crude oil from a secure area online.",
  },
  {
    icon: GiFamilyHouse,
    heading: "Real estate (Coming soon)",
    desc: "Unlock the Path to Prosperity with Real Estate Investing, we are REITs, Real Estate Investment Trusts, invest in large-scale, income-producing real estate. Our off plan project in Dubai and our own a property with as low as $20,000 capital project is coming to our investor soon.",
  },
  {
    icon: IoMdFlash,
    heading: "Renewable energy",
    desc: "Harness the Power of Renewable Energy: Invest in a Sustainable Future! Ark maintains its focus on innovative investments, we have an innovative approach to renewable energy investments, changing the society is one of our core focus, As the world shifts towards a greener future, now is the perfect time to align your financial goals with the urgent need for clean energy solutions.",
  },
  {
    icon: FaTrademark,
    heading: "Forex market",
    desc: "Forex trading, also known as currency trading or FX trading, is a decentralized global market where participants exchange one currency for another at agreed-upon exchange rates. It is the largest and most liquid financial market in the world, with daily trading volumes surpassing trillions of dollars.",
  },
];

export const inv_principles = [
  {
    icon: AiTwotoneAccountBook,
    heading: "Investment Research and Due Diligence",
    desc: "Our financial management company is dedicated to thorough research and due diligence. We leverage our extensive network and employ rigorous analysis to identify investment opportunities across various sectors and asset classes.",
  },
  {
    icon: GiTwoCoins,
    heading: "Regular Portfolio Review and Rebalancing",
    desc: "Financial markets are dynamic, and your investment strategy should adapt accordingly. We conduct regular portfolio reviews to assess performance, track progress towards your goals, and ensure alignment with changing market conditions. ",
  },
  {
    icon: MdDoneAll,
    heading: "Goal Setting and Risk Assessment",
    desc: "The first step in developing a successful investment strategy is to identify and prioritize your financial goals. Whether you seek capital growth, income generation, or capital preservation, clearly defined objectives enable us to design a tailored investment plan.",
  },
  {
    icon: GiCash,
    heading: "Tax Efficiency",
    desc: "Minimizing tax liabilities is a crucial aspect of effective investment strategies. We strive to optimize tax efficiency by employing tax-efficient investment vehicles, such as tax-advantaged accounts, and implementing tax-loss harvesting strategies",
  },
  {
    icon: AiOutlineBank,
    heading: "Asset Allocation",
    desc: "Asset allocation involves distributing your investment portfolio across different asset classes, such as stocks, bonds, cash equivalents, and alternative investments. Our company employs a disciplined approach to asset allocation, considering factors such as your investment goals, time horizon, and risk appetite.",
  },
  {
    icon: FiLayers,
    heading: "Diversification",
    desc: "Diversification is a key risk management tool that mitigates the impact of individual investment failures. We believe in spreading investments across different industries, sectors, and geographical regions.",
  },
];

export const inv_plans = [
  {
    id: 1,
    amount: "$4,999",
    insurance: "Available",
    max: "$4,999",
    min: "$500",
    popular: false,
    profit: "5.5%",
    referral: "5%",
    type: "Regular",
  },
  {
    id: 2,
    amount: "$29,999",
    insurance: "Available",
    max: "$29,999",
    min: "$5000",
    popular: true,
    profit: "8.5%",
    referral: "5%",
    type: "Premium",
  },
  {
    id: 3,
    amount: "$49,999",
    insurance: "Available",
    max: "$49,999",
    min: "$30,000",
    popular: false,
    profit: "11.5%",
    referral: "8%",
    type: "Zenith",
  },
  {
    id: 4,
    amount: "$100,000",
    insurance: "Available",
    max: "$100,000",
    min: "$50,000",
    popular: false,
    profit: "13.5%",
    referral: "10%",
    type: "Horizon",
  },
  {
    id: 5,
    amount: "Unlimited",
    insurance: "Available",
    max: "Unlimited",
    min: "$100,000",
    popular: false,
    profit: "15.5%",
    referral: "10%",
    type: "Retirement",
  },
  {
    id: 6,
    amount: "Unlimited",
    insurance: "Available",
    max: "Unlimited",
    min: "$1,000,000",
    popular: false,
    profit: "18.1%",
    referral: "N/A",
    type: "Contract",
  },
];

export const inv_equipped = [
  {
    heading: "Equity Trading",
    desc: "Unleash equity market potential with our robust trading infrastructure, providing real-time quotes, research, and support. Seize opportunities for investments and trading strategies with confidence.",
  },
  {
    heading: "Business Succession",
    desc: "Ensure your business's future success with our succession planning services. We guide you in creating a clear roadmap for a smooth transition, addressing legal, financial, and tax considerations.",
  },
  {
    heading: "Research and Analytics",
    desc: "Stay informed with our research and analytics. Our experts analyze market trends and provide actionable insights. Access reports and recommendations for well-informed trading decisions.",
  },
  {
    heading: "Corporate Finance",
    desc: "Unlock growth and profitability with our corporate finance solutions. We provide capital raising, debt financing, M&A, valuation, restructuring, and strategic consulting.",
  },
  {
    heading: "Investment Advisory",
    desc: "At Ark, we provide expert investment advisory services for individuals, families, and institutions. Our professionals offer tailored strategies to align with your goals.",
  },
  {
    heading: "Dedicated Customer Support",
    desc: "At Ark, Exceptional customer support is our priority. Get prompt and personalized assistance from our dedicated team for a smooth trading experience.",
  },
];

export const inv_more_focus = [
  {
    icon: FaBitcoin,
    heading: "Cryptocurrency",
    desc: "Ark doesn’t not focus solely on strategically challenging the assets of our investors in the right places to maximize profits, one of our core focus is also on offering investment solutions to capture disruptive innovation. Ark has researched the crypto space since its founding in 2014, and has been actively engaged in crypto related equity investments since 2015. A dedicated Crypto team within ARK manages these active portfolios utilizing a defined investment and risk management framework which seeks to capture alpha through macro evaluation, on-chain analytics, sentiment, and technical factors. Our Cryptocurrency Strategy is an actively managed, high conviction portfolio invested primarily in bitcoin and ether, the largest cryptocurrencies by network value.",
  },
  {
    icon: SiMarketo,
    heading: "Index market",
    desc: "The index market operates through stock exchanges, where stocks are listed and traded. Some of the well-known stock exchanges we trade actively with include the New York Stock Exchange (NYSE), Nasdaq, London Stock Exchange, and Tokyo Stock Exchange, while some very popular indices we trade include the S&P 500, Dow Jones Industrial Average, and FTSE 100. The most common method for investors to participate in the index market is investing in mutual funds or exchange-traded funds (ETFs) that replicate the performance of specific indices. Ark allow investors to gain exposure to a diversified portfolio of stocks without the need for individually buying and managing each stock, we day trade for you, and we definitely take a better advantage of the market volatility.",
  },
  {
    icon: GiOilPump,
    heading: "Oil & gas",
    desc: "More precisely, CFDs enable you to take position in just a few clicks, on buying and selling positions on the crude oil stock markets. You can thereby speculate on the rise or fall of the oil price and close your positions when the price per barrel has attained the price objective that you fixed. Your profit will correspond here to the price difference between the opening time and closing times of your position in proportion to the amount invested and if the price has moved in the direction you forecast. In the case to the contrary your loss will also represent this price difference. CFDs offer numerous advantages including the fact that they are very easy to use, even for individuals that are not used to investing alone on the financial markets. Another thing, they offer a leverage effect that enables an increase in the amount of your profits even with a small difference in rate. Of course, you also have a wide range of tools available such as orders that enable you to effectively manage your positions and reduce your risks. You will of course find lots of information about oil trading using CFDs on our website which also offers advice on how to best use these trading instruments. In this way you can start to use them when investing your capital in oil without having to go through an intermediary.",
  },
  {
    icon: GiFamilyHouse,
    heading: "Real estate (Coming soon)",
    desc: "Real estate investment remains a time-tested investment strategy that has consistently outperformed other asset classes. We are pulling your funds together and investing in, luxury apartments building, Rental Properties, Airbnb’s. Investing in our REIT Project provides you with a hassle-free, hands-off approach to real estate. Say goodbye to the complexities of property management, tenant issues, and maintenance concerns. We take care of everything for you, so you can focus on what truly matters—building your wealth and enjoying the benefits of passive income",
  },
  {
    icon: IoMdFlash,
    heading: "Renewable energy",
    desc: "Investing in renewable energy not only generates substantial returns but also creates a positive impact on the environment and society as a whole. This burgeoning sector offers a range of exciting opportunities that combine financial success with the advancement of clean technologies, It feels good to give your money a mission in saving the world. We have placed nearly 200 millions in transferable credits in the marketplace, our team of professionals leverages this expertise, knowledge, and network to serve as a leader in renewable energy development and financing support we specialize in identifying and nurturing promising renewable energy projects. Our team of experts conducts rigorous due diligence, assessing the financial viability, technology, regulatory environment, and long-term sustainability of each opportunity. We prioritize projects with strong fundamentals and a clear path to profitability, ensuring optimal returns for our investors. By investing with us, you become a part of the clean energy revolution. We provide regular updates, performance reports, and transparent communication to keep you informed and engaged in your sustainable investment journey. Together, let's drive positive change and capitalize on the vast potential of renewable energy. Invest wisely. Invest in renewable energy. Secure your financial future while making a meaningful difference for the planet",
  },
  {
    icon: FaTrademark,
    heading: "Forex market",
    desc: "At Ark we offer comprehensive forex trading services, empowering investors to participate in the dynamic foreign exchange market, we are committed to providing a secure, transparent, and efficient forex trading experience. Our goal remains to help you capitalize on forex market opportunities, manage risks effectively, and achieve your investment objectives in the foreign exchange market. With our Expert Forex Trading Team: Our team comprises skilled forex traders who possess a deep understanding of the global currency markets. With their expertise and experience, they analyze market trends, economic indicators, and geopolitical events to make informed trading decisions on behalf of our clients and to generate high yield for our investors.",
  },
];

export const accordionData = [
  {
    title: "How can I track the performance of my investments?",
    desc: "Once you open an account, you'll have access to a user-friendly dashboard that allows you to monitor the performance of your investments in real-time. You can also receive regular investment reports and updates via email.",
  },
  {
    title: "What is an Investment Platform?",
    desc: "An investment platform is an online service which provides access to funds from a wide range of Investment Managers, which means that you can pick various funds from various Investment Managers but you do not have to go to each one of them individually as they are bought by the Platform provider on your behalf. You can hold the various types of product on the Platform such as ISA, Pension, Investment Bonds, Investment Trusts, OEIC’s ETF’s etc.",
  },
  {
    title: "Can I make changes to my investment portfolio?",
    desc: "Absolutely! We believe in giving our clients flexibility. You can adjust your investment portfolio by buying or selling assets based on your changing financial goals and risk tolerance.",
  },
  {
    title: "Ark Investment Management Limited?",
    desc: "Ark Investment Management Limited is a financial and investment management company duly registered with registration number RC:02020394.    ",
  },
  {
    title: "Is there a minimum investment requirement?    ",
    desc: "The minimum investment requirement varies depending on the type of investment product you choose. Please refer to our website packages section or contact our support team for specific details.    ",
  },
  {
    title: "In which countries do you provide consulting services?",
    desc: "As an international company we accept clients from all countries and help them build strong financial stability.",
  },
  {
    title: "Do you offer tax-related assistance for investments?",
    desc: "While we do not provide tax advice ourselves, we can provide relevant investment-related tax information. For specific tax advice, we recommend consulting a tax professional.",
  },
  {
    title: "Who controls the investment Portfolio?",
    desc: "The investment portfolio is managed by a team of experienced financial specialists, of lawyers, professional trade analysts who have been working on the currency and stock exchange market for more than 10 years on average. Our experience and contacts ensure access for us to a wide range of local and global resources and bring about benefit from the world’s best and most effective technologies of trading on the real estate,oil,Forex, Binary and Cryptocurrency market.",
  },
  {
    title: "Can I automate my investments on your platform?",
    desc: "Yes, we offer automated investment options through features like dollar-cost averaging and automated rebalancing. These tools can help you stay disciplined and manage your portfolio efficiently.",
  },
  {
    title: "Are my investments protected?",
    desc: "Yes, we take the security of your investments seriously. We use industry-standard encryption and security measures to safeguard your account and personal information. Additionally, investments in certain assets may be protected by regulatory agencies.",
  },
  {
    title: "Do you offer any investment incentives or bonuses?",
    desc: " While we do offer bonuses and incentives on special occasions at the discretion of the management, we provide competitive investment opportunities with transparent fees. Our focus is on delivering reliable, long-term returns for our clients",
  },
  {
    title: "What is the duration of the withdrawal mode?",
    desc: "Absolutely! We believe in giving our clients flexibility. You can adjust your investment portfolio by buying or selling assets based on your changing financial goals and risk tolerance.",
  },
  {
    title:
      "Are my investments covered by insurance in case of a market downturn?",
    desc: "Investments are insured against market losses or changes in asset values. Capital insurance provides financial protection against the loss of capital or investment. It is designed to safeguard the principal amount invested. However, it's crucial to have a well-structured portfolio that aligns with your risk tolerance to weather market fluctuations effectively.",
  },
  {
    title: "What happens when extra profit is made?",
    desc: "Extra profits will be used to pay referral commissions, announce incentives and reward the traders for their diligence. We will also increase our insurance fund.",
  },
  {
    title: "How can I track the performance of my investments?",
    desc: "Once you open an account, you'll have access to a user-friendly dashboard that allows you to monitor the performance of your investments in real-time. You can also receive regular investment reports and updates via email.",
  },
  {
    title: "How long does it take to process transactions?",
    desc: "Generally transactions are processed within minutes. Processing time also depends on the payment method.",
  },
  {
    title: "Can I have an income from my investments?",
    desc: "Yes you can have an income, either from the withdrawal mode or compounding, a later date if income is not needed straight away, this is something we will clarify with you when deciding which is investment is most suitable.",
  },
  {
    title: "How long do I need to tie my money up for?",
    desc: "There is no need to tie your money up for any specific time period and is down to your own personal requirements although it is recommended that stock market related investments should be envisaged to be kept for 3 to 5 years.",
  },
  {
    title: "Is bitcoin/USDT your only deposit currency?",
    desc: "NO. Deposit currency is USD and BTC is used to transact money smoothly. Appreciation or depreciation in the value of BTC after you have made the deposit will not in any way affect your deposit rather the conversion rate at the time of deposit applies.",
  },
  {
    title: "What risk do I have to take to generate a good return on my money?",
    desc: "Everybody has a different concept of what the word risk means so before recommending an investment, we carry out an ‘Attitude to Risk’ analysis that is personal to you so that your investment is matched to your own personal risk rating plus we then offer regular reviews which includes a review of your attitude to risk in order to ensure that your investment remains suitable.",
  },
];

export const are_you_1 = [
  { dsc: "Are you saving for a house?" },
  { dsc: "Are you trying to sort out your debts?" },
  { dsc: "Do you need to save for a rainy day?" },
  { dsc: "When are you planning to retire?" },
  { dsc: "Are you planning to buy a new car?" },
  { dsc: "Do you need to find a good pension?" },
  { dsc: "Are you looking for a better return for your investments?" },
];

export const are_you_2 = [
  { dsc: "Are you married or single?" },
  { dsc: "Do you have children or other dependents?" },
  { dsc: "Do you have a mortgage or are you renting?" },
  { dsc: "How much do you earn?" },
  { dsc: "How safe is your job?" },
];

export const inv_types = [
  {
    picture: "/images/basic.png",
    plan: "Basic",
    min: "$500",
    max: "$4,999",
    ref: "5%",
    weekly: "3%",
  },
  {
    picture: "/images/standard.png",
    plan: "Standard",
    min: "$5,000",
    max: "$29,999",
    ref: "6%",
    weekly: "4.5%",
  },
  {
    picture: "/images/premium.png",
    plan: "Premium",
    min: "$30,000",
    max: "$69,999",
    ref: "7%",
    weekly: "6.5%",
  },
  {
    picture: "/images/zenith.png",
    plan: "Zenith",
    min: "$70,000",
    max: "$199,999",
    ref: "8%",
    weekly: "8.5%",
  },
  {
    picture: "/images/elite.png",
    plan: "Elite",
    min: "$200,000",
    max: "$1,000,000",
    ref: "9%",
    weekly: "11%",
  },
  {
    picture: "/images/contract.png",
    plan: "Contract",
    min: "$1,000,000",
    max: "-",
    ref: "N/A",
    weekly: "13.5%",
  },
];
