import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  EffectFade,
  Autoplay,
  Scrollbar,
  A11y,
  Pagination,
} from "swiper/modules";

export default function Testimonials() {
  return (
    <div className="bg-main py-[30px] w-full flex flex-col md:flex-row ">
      <div className=" w-full md:w-[80%] min-h-[100px] md:min-h-[400px] flex flex-col justify-center m-auto text-white ">
        <h1 className=" text-[22px] md:text-[32px] text-center font-bold ">
          Testimonials
        </h1>
        <p className=" py-[25px] w-[70%] m-auto md:text-[18px] text-center ">
          Explore the valuable feedback we've received from individuals about
          their experiences with Ark Financial Management Limited:
        </p>
        <div className=" w-full pt-[30px] ">
          <Swiper
            modules={[
              Navigation,
              EffectFade,
              Pagination,
              Scrollbar,
              A11y,
              Autoplay,
            ]}
            navigation
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            speed={1000}
            spaceBetween={800}
            slidesPerView={1}
            loop
            autoplay={{
              delay: 7000,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <div className="flex min-h-[50vh] md:min-h-[70vh] flex-col items-center ">
                <div className=" h-[180px] w-[180px] rounded-full bg-black "></div>
                <p className=" py-[30px] w-[80%] md:w-[50%] text-center ">
                  "One thing I must say is that Ark is for everyone, I had
                  little knowledge of investing before coming across Ark, Their
                  educational resources and patient support have given me the
                  tools to make informed decisions. I'm grateful for the
                  positive impact on my financial future"
                </p>
                <p className=" py-[30px] w-[50%] text-center ">
                  <b>- Emily T-</b>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex min-h-[50vh] md:min-h-[70vh] flex-col items-center ">
                <div className=" h-[180px] w-[180px] rounded-full bg-black "></div>
                <p className=" py-[30px] w-[80%] md:w-[50%] text-center ">
                  "Ark truly stands out from the rest. Their exceptional
                  customer service and prompt responses have made my investment
                  journey stress-free. The efficient customer service makes me
                  feel like a valued client every step of the way."
                </p>
                <p className="py-[30px] w-[50%] text-center">
                  <b>-Mark P.-</b>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex min-h-[50vh] md:min-h-[70vh] flex-col items-center ">
                <div className=" h-[180px] w-[180px] rounded-full bg-black "></div>
                <p className=" py-[30px] w-[80%] md:w-[50%] text-center ">
                  From the first consultation with Andra” to ongoing support,
                  Ark has proven to be a reliable partner in achieving my
                  financial goals. Their team's knowledge and genuine interest
                  in my success have made all the difference. I couldn't ask for
                  a better investment firm
                </p>
                <p className=" py-[30px] w-[50%] text-center ">
                  <b>-Laura H-</b>
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex min-h-[50vh] md:min-h-[70vh] flex-col items-center ">
                <div className=" h-[180px] w-[180px] rounded-full bg-black "></div>
                <p className=" py-[30px] w-[80%] md:w-[50%] text-center ">
                  Choosing Ark was one of the best decisions I've made. The
                  team's professionalism and dedication are evident in the
                  impressive returns my portfolio has achieved. I highly
                  recommend them to anyone serious about securing their
                  financial future
                </p>
                <p className=" py-[30px] w-[50%] text-center ">
                  <b>-Jessica R-</b>
                </p>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
