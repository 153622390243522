import React, { useState } from "react";
import LayoutOne from "../Layout/Layout_One";
import Imgsection from "../components/Comp_Layouts/HeroTwo";
import { Link } from "react-router-dom";
import { routeObj } from "../constants/routes";
import { useLogin } from "../Hooks/authHooks";
import { FormInput } from "../components/Comp_Layouts/Form";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import { Button } from "../components/Button";

export default function Login() {
  const { login } = useLogin();
  const [showPassword, setShowPassword] = useState(false);

  return (
    <LayoutOne>
      <Imgsection image="/images/banner29.jpg" title="Login" />
      <div className="py-[20px] pb-[20px] md:pb-[30px] w-[90%] md:w-[50%] m-auto text-teal-700  ">
        <p className=" pb-0 md:pb-[30px] text-[20px] md:text-[26px] font-[600] text-sub_main text-left md:text-center ">
          Login To Your Account
        </p>

        <form onSubmit={login.handleSubmit} autoComplete="off">
          <FormInput
            error={login.errors.email!}
            onBlur={login.handleBlur}
            onChange={login.handleChange}
            type="text"
            value={login.values.email}
            className=" "
            name="email"
          />

          <FormInput
            error={login.errors.password!}
            onBlur={login.handleBlur}
            onChange={login.handleChange}
            type={showPassword ? "text" : "password"}
            icon={showPassword ? PiEyeLight : PiEyeSlashLight}
            value={login.values.password}
            className="  "
            name="password"
            onClick={() => {
              showPassword ? setShowPassword(false) : setShowPassword(true);
            }}
          />

          <div className=" flex justify-end ">
            <Button
              loading={login.isSubmitting}
              className=" bg-main w-full md:w-[130px] mt-[30px] md:mt-[25px] rounded-xl text-[1rem] md:text-[18px] py-[15px] md:py-[10px] text-white  "
              type="submit"
            >
              Login
            </Button>
          </div>
        </form>

        <div className=" flex  w-full md:w-[70%] m-auto mt-[10px] pt-[20px] min-h-[50px] justify-between ">
          <Link to={routeObj.register} color="blue.500">
            Dont Have An Account?
          </Link>
          <Link to={routeObj.forgot} color="blue.500">
            Forgot Password
          </Link>
        </div>
      </div>
    </LayoutOne>
  );
}
