export default function OnlyAtArk() {
  return (
    <div className="bg-main py-[30px] flex flex-col md:flex-row">
      <div className="w-full min-h-[400px] flex-col justify-center pl-[40px] text-black ">
        <h1 className=" text-[20px] md:text-[30px] text-sub_main ">
          ONLY AT ARK
        </h1>
        <p className=" py-[25px] w-[80%] text-[15px] md:text-[20px] text-sub_main ">
          There are variety of reasons to include hedge funds in a portfolio of
          otherwise traditional investments. The most cited reason to include
          them in any portfolio is their ability to reduce risk and add
          diversification.
        </p>
        <div className=" flex flex-col md:flex-row justify-center md:justify-between flex-wrap w-full ">
          <div className=" w-[90%] md:w-[25%] border-4 border-[#ccb884] bg-white shadow-2xl md:shadow-none text-black h-[220px] md:h-[250px] p-[10px] md:ml-[20px] mt-[30px] flex flex-col ">
            <div className="flex justify-center items-center h-[20%] text-[20px] w-full font-bold ">
              Ideas in Action
            </div>
            <div className="flex justify-center items-center w-full text-center text-[14px] md:text-[18px] ">
              We will explore any territory in pursuit of an idea, taking
              unconventional approaches and making big, bold investments in
              unexpected places
            </div>
          </div>

          <div className=" w-[90%] md:w-[25%] border-4 border-[#ccb884] bg-white shadow-2xl md:shadow-none text-black h-[220px] md:h-[250px] p-[10px] md:ml-[20px] mt-[30px] flex flex-col ">
            <div className="flex justify-center items-center h-[20%] text-[20px] w-full font-bold ">
              Financial well-being
            </div>
            <div className="flex justify-center items-center w-full text-center text-[14px] md:text-[18px]">
              We help millions of people invest to build savings that serve them
              throughout their lives. As it becomes harder to save for goals
              like retirement, how can we help more people invest for their
              future?
            </div>
          </div>

          <div className=" w-[90%] md:w-[25%] border-4 border-[#ccb884] bg-white shadow-2xl md:shadow-none text-black h-[220px] md:h-[250px] p-[10px] md:ml-[20px] mt-[30px] flex flex-col ">
            <div className="flex justify-center items-center h-[20%] text-[20px] w-full font-bold ">
              Retirement plan
            </div>
            <div className="flex justify-center items-center w-full text-center text-[14px] md:text-[18px]">
              Ark's purpose is to help more and more people experience financial
              well-being. This starts with making it easier and more affordable
              for people to invest in resilient retirement plans.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
