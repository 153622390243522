import React from "react";
import Layout from "../../Layout/Layout";

export default function UsersProfit() {
  return (
    <Layout>
      <div></div>
    </Layout>
  );
}
