import { AdminLinkItems, LinkItems } from "./Links";
import { NavLink } from "./NavLink";
import { FiLogOut } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { useSelector } from "react-redux";
import { SelectAuth } from "../../store/slice/auth";
import { useLogout } from "../../Hooks/authHooks";

export default function SideBar2() {
  const { user } = useSelector(SelectAuth);
  const { Logout } = useLogout();

  return (
    //commented hidden out........a
    <div className="w-[100%] py-[50px] text-white   bg-main md:w-[18%]  h-[100vh]  overflow-y-scroll  ">
      <div className=" pl-5  flex gap-5 items-center  h-[80px]  ">
        {user?.image ? (
          <img src={user?.image} className=" h-full rounded-full " alt="" />
        ) : (
          <CgProfile className="text-[50px]" />
        )}
        <div>
          <p>{user?.fullname}</p>
          <p>{user?.email}</p>
        </div>
      </div>
      <div className="flex flex-col w-full bg-red-00 h-full md:justify-around">
        {user && user?.type === "ADMIN"
          ? AdminLinkItems?.map((item) => <NavLink key={item.name} {...item} />)
          : LinkItems?.map((item) => <NavLink key={item.name} {...item} />)}
        <button
          onClick={() => Logout()}
          className=" w-full mt-10 md:mt-0 text-sub_main  text-left flex gap-5 px-5 "
        >
          {" "}
          <div className=" mb-10 pb-5 md:pb-0 text-[20px] ">
            <FiLogOut fill="#d9bf84" />
          </div>{" "}
          Signout
        </button>
      </div>
    </div>
  );
}
