export type DashboardDetailsType = {
  total: {
    referrals: number;
    depositTot: number;
    profitTot: number;
    withdrawalTot: number;
    current: number;
    referrals_earned: number;
  };
  adminTotal: {
    depositTot: number;
    profitTot: number;
    withdrawalTot: number;
    current: number;
  };
  loading: boolean;
};

export const initialState: DashboardDetailsType = {
  total: {
    referrals_earned: 0,
    referrals: 0,
    current: 0,
    depositTot: 0,
    profitTot: 0,
    withdrawalTot: 0,
  },
  adminTotal: {
    current: 0,
    depositTot: 0,
    profitTot: 0,
    withdrawalTot: 0,
  },
  loading: false,
};
