import classNames from "classnames";
import { PulseLoader } from "react-spinners";

type Props = {
  type?: "submit" | "button";
  className?: string;
  loading?: boolean;
  id?: string;
  onClick?: (e?: any) => void;
  children: React.ReactNode;
};

export const Button = ({
  children,
  type,
  className,
  id,
  loading,
  onClick,
}: Props) => {
  return (
    <button
      type={type}
      className={classNames(
        `${className} ${
          loading ? "bg-disabled" : ""
        } my-7  w-full py-3 text-center  rounded-lg `
      )}
      id={id}
      onClick={onClick}
      disabled={loading}
    >
      {loading ? <PulseLoader loading={true} color="white" /> : children}
    </button>
  );
};
