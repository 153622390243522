import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import { routeObj } from "../../constants/routes";
import { useEffect } from "react";

interface Props {
  title?: string;
  image: string;
  sub_title?: string;
}

export default function Imgsection(props: Props) {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [imgageb, inViewImage] = useInView({
    threshold: 0.5, // Percentage of visibility required to trigger the animation
  });

  return (
    <div className="bg-main pb-[30px] ">
      <div
        className=" min-h-[45vh] md:min-h-[70vh] bg-center bg-cover bg-no-repeat "
        style={{ backgroundImage: `url(${props?.image})` }}
      >
        <div className="flex justify-center items-center bg-rgba  min-h-[45vh] md:min-h-[70vh] pl-[10px] md:pl-[50px] ">
          <div
            className="duration-300 bg-red-00 delay-75 ease-in-out h-[50%] px-[30px] mb-[90px] md:mb-0 flex flex-col "
            ref={imgageb}
            style={{ opacity: inViewImage ? 1 : 0 }}
          >
            <div className=" w-full h-full flex flex-col justify-center items-center ">
              <h1
                className={`p-[7px] md:p-[15px] font-[500] md:text-[40px] text-[#e5e5e5] ${
                  props?.sub_title ? "text-[20px]" : "text-[40px]"
                } `}
              >
                {props.sub_title ? props.sub_title : props.title}
              </h1>

              <div className="flex p-[7px] md:p-[15px] px-[10px] text-[16px] md:px-[20px] rounded-sm font-[400] text-[#e5e5e5]  ">
                <Link to={routeObj.landing}>
                  <p>Home / </p>
                </Link>
                <p className="ml-[5px]"> {props.title}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
