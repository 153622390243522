import * as Yup from "yup";
export default Yup.object({
  fullname: Yup.string().required("Fullname Required").min(6, "Too Short!"),
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Password is Required"),
  email: Yup.string().email("Invalid email").required("Invalid Email"),
  gender: Yup.string(),
  phone: Yup.string()
    .min(2, "Too Short!")
    .max(14, "Too Long!")
    .required("Phone Number Required"),
  country: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Country Required"),
});
