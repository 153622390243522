import { memo, useEffect } from "react";
import Layout from "../../Layout/Layout";
import Pagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { SelectAdmin } from "../../store/slice/admin";
import { getWithdrawals } from "../../store/service/adminApi";
import { ScaleLoader } from "react-spinners";
import { Fmtd_Date } from "../../utils/date";

export default function AllWIthdrawals() {
  const dispatch = useDispatch<AppDispatch>();
  const { withdrawal, loading, pagination } = useSelector(SelectAdmin);
  const FetchData = async (params: { page: number } | null) => {
    try {
      await dispatch(getWithdrawals(params));
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequest = (val: number) => {
    return FetchData({ page: val });
  };

  useEffect(() => {
    FetchData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <div className=" relative  min-w-0 break-words w-full  ">
        <p className=" text-[20px] md:text-[25px] font-[700] ">Withdrawals</p>
        <div className="dash_webkit block pt-10 w-full overflow-x-auto ">
          <table className=" bg-red-00 w-full md:w-full ">
            <thead className=" mb-7 ">
              <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Withdrawer
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Current Profit
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#d7d7d7]">
              {!loading &&
                withdrawal.length !== 0 &&
                withdrawal?.map((withdrawal) => (
                  <TableRow
                    key={withdrawal.id}
                    fullname={withdrawal.fullname!}
                    profit={withdrawal?.profit}
                    date={Fmtd_Date(withdrawal.created_at)}
                  />
                ))}

              {!loading && withdrawal.length === 0 && (
                <tr>
                  <td className="py-0 text-center " colSpan={6}>
                    <div className="w-full py-10 font-bold bg-main text-white text-[20px] flex justify-center items-center ">
                      You Don't Have Any User
                    </div>
                  </td>
                </tr>
              )}

              {loading && (
                <tr>
                  <td className="py-6 text-center " colSpan={6}>
                    <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                      <ScaleLoader />
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td className="pt-6" colSpan={6}>
                  <Pagination
                    currentPage={pagination?.current}
                    totalPages={pagination?.total}
                    sendRequest={sendRequest}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

const TableRow = memo(
  ({
    fullname,
    date,
    profit,
  }: {
    fullname: string;
    date: string;
    profit: string;
  }) => {
    return (
      <tr className={`text-left border-b-2  border-[#d7d7d7]`}>
        <td className="py-5 whitespace-nowrap px-3">{fullname}</td>
        <td className="py-5 whitespace-nowrap px-3">${profit}</td>
        <td className="py-5 whitespace-nowrap px-3">{date}</td>
      </tr>
    );
  }
);
