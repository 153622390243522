import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./initial";
import { RootState } from "../../index";
import {
  getUsers,
  getDeposits,
  getWithdrawals,
  getRequest,
  getBlogs,
  getUpdates,
  getNotifications,
  getActivities,
  getReferrals,
} from "../../service/adminApi";

export const adminnSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // Users
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.users = payload.data.list;
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.rejected, (state) => {
        state.loading = false;
      })

      // Deposits
      .addCase(getDeposits.fulfilled, (state, { payload }) => {
        state.deposit = payload.data.list;
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getDeposits.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDeposits.rejected, (state) => {
        state.loading = false;
      })

      // Withdrawal
      .addCase(getWithdrawals.fulfilled, (state, { payload }) => {
        state.withdrawal = payload.data.list;
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getWithdrawals.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWithdrawals.rejected, (state) => {
        state.loading = false;
      })

      // Request
      .addCase(getRequest.fulfilled, (state, { payload }) => {
        state.request = payload.data.list;
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRequest.rejected, (state) => {
        state.loading = false;
      })

      // Blog
      .addCase(getBlogs.fulfilled, (state, { payload }) => {
        state.blog = payload.data.list;
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlogs.rejected, (state) => {
        state.loading = false;
      })

      // Blog
      .addCase(getUpdates.fulfilled, (state, { payload }) => {
        state.update = payload.data.list;
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getUpdates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUpdates.rejected, (state) => {
        state.loading = false;
      })

      // Notification
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.notification = payload.data.list;
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getNotifications.rejected, (state) => {
        state.loading = false;
      })

      // Activity
      .addCase(getActivities.fulfilled, (state, { payload }) => {
        state.activities = payload.data.list;
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getActivities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActivities.rejected, (state) => {
        state.loading = false;
      })

      // Referrals
      .addCase(getReferrals.fulfilled, (state, { payload }) => {
        state.referals = payload.data.list;
        state.pagination = payload.data.pagination;
        state.loading = false;
      })
      .addCase(getReferrals.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReferrals.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default adminnSlice.reducer;
export const SelectAdmin = (state: RootState) => state.admin;
