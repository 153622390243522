import { ReactNode } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function LayoutOne({ children }: { children: ReactNode }) {
  return (
    <div>
      <Header />
      <div>{children}</div>
      <Footer />
    </div>
  );
}
