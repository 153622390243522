import { useState } from "react";
import LayoutOne from "../Layout/Layout_One";
import { AccordionIT } from "../components/Comp_Layouts/AccordionIT";
import Imgsection from "../components/Comp_Layouts/HeroTwo";
import { accordionData, are_you_1, are_you_2 } from "../utils/data";
import { MdCheckCircle } from "react-icons/md";

export default function Faqs() {
  const [selected, setSelected] = useState<number | null>(null);

  return (
    <LayoutOne>
      <Imgsection
        image="/images/banner30.jpg"
        sub_title="Asked Questions?"
        title="Faqs"
      />
      <div className=" bg-[#fbf9f9] pt-[30px] ">
        <p className="text-center text-[20px] md:text-[30px] text-sub_main ">
          MOST POPULAR QUESTIONS ?
        </p>
        <p className=" text-center w-[90%] md:w-[80%] m-auto pt-[10px] text-[14px] md:text-[18px] text-[#111]  ">
          We help you see the world differently, discover opportunities you may
          never have imagined and achieve results that bridge what is with that
          can be.
        </p>
        <div className=" w-full md:w-[90%] min-h-[200px] m-auto mt-[20px] p-[10px] text-[14px] md:text-[18px] ">
          {accordionData?.map((accord, index) => (
            <AccordionIT
              key={index}
              title={accord?.title}
              desc={accord?.desc}
              isActive={index === selected}
              setSelected={setSelected}
              sectionIndex={index}
            />
          ))}
        </div>
        <div className=" bg-main py-[55px] text-[14px] md:text-[18px] flex flex-col ">
          <p className=" text-[16px] md:text-[30px] w-[78%] m-auto py-[20px] text-white ">
            WHAT WE WANT TO KNOW ABOUT OUR CLIENT.
          </p>
          <p className=" w-[78%] m-auto text-white ">
            Here at Ark Investment Management we are completely focused on you
            and your needs. We like to get to know you, to find out about your
            personal goals and the big financial picture for you. We want to
            know what your plans are for your life, so we can find the best way
            to structure your finances to achieve your goals.
          </p>
          <ul className="space-y-4 w-[78%] m-auto py-[20px] text-[#e5e5e5]  ">
            {are_you_1?.map((item, index) => (
              <div className="flex items-center gap-3 " key={index}>
                <MdCheckCircle fill="green" />
                {item.dsc}
              </div>
            ))}
          </ul>
          <p className=" w-[78%] m-auto text-white ">
            We’re also practical and pragmatic about the advice we offer. Our
            advice is based on your own individual circumstances, such as:
          </p>
          <ul className="space-y-4 w-[78%] m-auto py-[20px] text-[#e5e5e5]">
            {are_you_2?.map((item, index) => (
              <div className="flex items-center gap-3 " key={`are-${index}`}>
                <MdCheckCircle fill="green" />
                {item.dsc}
              </div>
            ))}
          </ul>
          <p className=" w-[78%] m-auto text-white ">
            We take all this information into account when we’re suggesting
            plans and services that are specifically tailored to you.
          </p>
        </div>
      </div>
    </LayoutOne>
  );
}
