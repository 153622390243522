import { memo, useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import { Fmtd_Date } from "../../utils/date";
import Pagination from "../../components/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { SelectAdmin } from "../../store/slice/admin";
import { getUpdates, updateNotifier } from "../../store/service/adminApi";
import { PulseLoader, ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import ModalP from "../../components/modal/ModalP";
import { useCreateNotifier } from "../../Hooks/Notifier";
import { FormInput } from "../../components/Comp_Layouts/Form";
import { Button } from "../../components/Button";
import SwitchButton from "../../components/SwitchButton";

export default function AllUpdates() {
  const dispatch = useDispatch<AppDispatch>();
  const { update, loading, pagination } = useSelector(SelectAdmin);
  const [isLoading, setIsLoading] = useState(false);
  const [create, setCreate] = useState(false);
  const ToggleCreate = () => setCreate(!create);
  const { notifier } = useCreateNotifier(null, ToggleCreate);
  const FetchData = async (params: { page: number } | null) => {
    try {
      await dispatch(getUpdates(params));
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequest = (val: number) => {
    return FetchData({ page: val });
  };

  const handleAction = async (params: { id: string; status: string }) => {
    try {
      setIsLoading(true);
      const req = await dispatch(updateNotifier(params)).unwrap();
      toast.success(req.message);
      await FetchData(null);
      setIsLoading(false);
    } catch (error) {
      toast.error("Update Failed");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    FetchData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <ModalP
        hasCancelButton={false}
        modalOpen={isLoading}
        handleClose={() => {}}
      >
        <div className=" px-10 py-4 ">
          <PulseLoader />
        </div>
      </ModalP>
      <ModalP modalOpen={create} handleClose={ToggleCreate}>
        <div className=" rounded-2xl min-w-[300px] md:w-[500px] px-3 py-4 ">
          <form onSubmit={notifier.handleSubmit}>
            <div className=" mt-10 grid grid-cols-1 md:grid-cols-1 gap-8">
              <FormInput
                name="title"
                className=""
                label="Title"
                margin={1}
                type="text"
                error={notifier.errors.title}
                onBlur={notifier.handleBlur}
                onChange={notifier.handleChange}
                value={notifier.values.title}
              />

              <FormInput
                name="description"
                className=""
                label="Description"
                margin={1}
                type="text"
                error={notifier.errors.description}
                onBlur={notifier.handleBlur}
                onChange={notifier.handleChange}
                value={notifier.values.description}
              />
            </div>

            <div className="  mt-10 w-full gap-5 bg-red-00 flex justify-end ">
              <Button
                loading={notifier.isSubmitting}
                className="py-3 w-full md:w-[200px] px-10 bg-main text-white rounded-lg"
                type="submit"
              >
                Create
              </Button>
            </div>
          </form>
        </div>
      </ModalP>
      <div className=" relative  min-w-0 break-words w-full  ">
        <div className="flex justify-between items-center">
          <p className=" text-[20px] md:text-[25px] font-[700] ">Updates</p>
          <button
            onClick={ToggleCreate}
            className=" bg-ubmain py-3 px-7 rounded-lg "
          >
            Create Notification
          </button>
        </div>
        <div className="dash_webkit block pt-10 w-full overflow-x-auto ">
          <table className=" bg-red-00 w-full md:w-full ">
            <thead className=" mb-7 ">
              <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Notifier Id
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Title
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Created
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#d7d7d7]">
              {!loading &&
                update.length !== 0 &&
                update?.map((update) => (
                  <TableRow
                    key={update.id}
                    id={update.id}
                    title={update.title}
                    status={update?.status}
                    created={Fmtd_Date(update.created_at)}
                    onChange={(params: { id: string; status: string }) =>
                      handleAction(params)
                    }
                  />
                ))}

              {!loading && update.length === 0 && (
                <tr>
                  <td className="py-0 text-center " colSpan={6}>
                    <div className="w-full py-10 font-bold bg-main text-white text-[20px] flex justify-center items-center ">
                      You Don't Have Any Record
                    </div>
                  </td>
                </tr>
              )}

              {loading && (
                <tr>
                  <td className="py-6 text-center " colSpan={6}>
                    <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                      <ScaleLoader />
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td className="pt-6" colSpan={6}>
                  <Pagination
                    currentPage={pagination?.current}
                    totalPages={pagination?.total}
                    sendRequest={sendRequest}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

const TableRow = memo(
  ({
    id,
    title,
    created,
    status,
    onChange,
  }: {
    status: "ACTIVE" | "INACTIVE";
    id: string;
    title: string;
    created: string;
    onChange: (params: { id: string; status: string }) => void;
  }) => {
    return (
      <tr className={`text-left border-b-2  border-[#d7d7d7]`}>
        <td className="py-5 whitespace-nowrap px-3">{id}</td>
        <td className="py-5 whitespace-nowrap px-3">{title}</td>
        <td className="py-5 whitespace-nowrap px-3">{created}</td>
        <td className="py-5 whitespace-nowrap px-3">
          <div className="flex gap-3 items-center ">
            <SwitchButton
              checked={status === "ACTIVE"}
              onChange={() =>
                onChange({
                  id,
                  status: status === "ACTIVE" ? "INACTIVE" : "ACTIVE",
                })
              }
            />
          </div>
        </td>
      </tr>
    );
  }
);
