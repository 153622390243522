import { ReactNode, useEffect } from "react";
import SideBar from "../ProtectedPages/components/SideBar";
import NavBar from "../ProtectedPages/components/NavBar";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { getUsersUpdates } from "../store/service/common";
import { SelectUpdate, setUpdateActive } from "../store/slice/update";
import ModalP from "../components/modal/ModalP";
import AccountUpdate from "../components/Comp_Layouts/AccountUpdate";
import { useCheckAuth } from "../Hooks/authHooks";

export default function Layout({ children }: { children: ReactNode }) {
  useCheckAuth();
  const dispatch = useDispatch<AppDispatch>();
  const { active, data } = useSelector(SelectUpdate);

  const init = async () => {
    try {
      await dispatch(getUsersUpdates());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex overflow-y-scroll ">
      <ModalP
        modalOpen={active}
        handleClose={() => {
          dispatch(setUpdateActive(false));
        }}
      >
        <AccountUpdate description={data?.description!} title={data?.title!} />
      </ModalP>
      <SideBar />
      <div className="relative w-full md:w-[82%] bg-[#e5e5e5] h-[100%] ">
        <div>
          <NavBar />
        </div>
        <div className=" h-[89vh]  overflow-scroll ">
          <div className=" px-3 md:px-6 pt-10 pb-[200px] md:pb-[300px] ">
            {children}
          </div>
        </div>
        <div className="absolute z-30 bottom-0 left-0 right-0 bg-main border-2 py-5 px-4 border-[#d9bf84] ">
          <div className="flex justify-between text-sub_main ">
            Ark
            <i>@ 2024 all rights reserved</i>
          </div>
        </div>
      </div>
    </div>
  );
}
