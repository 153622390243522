import Layout from "../Layout/Layout";
import { FormInput, SelectInput } from "../components/Comp_Layouts/Form";
import { useEditProfile } from "../Hooks/userHooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { SelectAuth } from "../store/slice/auth";
import { Button } from "../components/Button";

export default function EditProfile() {
  const { user } = useSelector(SelectAuth);
  const { editProfile } = useEditProfile(user);
  const navigate = useNavigate();

  return (
    <Layout>
      <form onSubmit={editProfile.handleSubmit} autoComplete="off">
        <p className=" text-[20px] font-bold ">Edit Profile</p>
        <div className=" md:mt-20 flex flex-wrap justify-between ">
          <div className=" py-6 px-3 w-full md:w-[45%] bg ">
            <FormInput
              name="fullname"
              className=""
              margin={1}
              type="text"
              error={editProfile.errors.fullname!}
              onBlur={editProfile.handleBlur}
              onChange={editProfile.handleChange}
              value={editProfile.values.fullname}
            />
          </div>
          <div className=" py-6 px-3 w-full md:w-[45%] bg ">
            <FormInput
              name="email"
              className=""
              margin={1}
              type="text"
              error={editProfile.errors.email!}
              onBlur={editProfile.handleBlur}
              onChange={editProfile.handleChange}
              value={editProfile.values.email}
            />
          </div>
          <div className=" py-6 px-3 w-full md:w-[45%] bg ">
            <FormInput
              name="phone"
              className=""
              margin={1}
              type="text"
              error={editProfile.errors.phone!}
              onBlur={editProfile.handleBlur}
              onChange={editProfile.handleChange}
              value={editProfile.values.phone}
            />
          </div>
          <div className=" py-6 px-3 w-full md:w-[45%] bg ">
            <FormInput
              name="country"
              className=""
              margin={1}
              type="text"
              error={editProfile.errors.country!}
              onBlur={editProfile.handleBlur}
              onChange={editProfile.handleChange}
              value={editProfile.values.country}
            />
          </div>
          <div className=" py-0 px-3 w-full md:w-[45%] bg ">
            <SelectInput
              name="crypto"
              className=" outline-none bg-red-500 "
              onChange={editProfile.handleChange}
              onBlur={editProfile.handleBlur}
              value={editProfile.values.crypto}
            >
              <option value="bitcoin">Bitcoin</option>
              <option value="usdt(erc20)">USDT (ERC20)</option>
              <option value="usdt(trc20)">USDT (TRC20)</option>
            </SelectInput>
          </div>
          <div className=" py-6 px-3 w-full md:w-[45%] bg ">
            <FormInput
              name="address"
              className=""
              margin={1}
              type="text"
              error={editProfile.errors.address!}
              onBlur={editProfile.handleBlur}
              onChange={editProfile.handleChange}
              value={editProfile.values.address}
            />
          </div>
        </div>
        <div className=" flex justify-end px-5 gap-5 items-center ">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className=" bg-sub_main py-3 px-10 rounded-lg "
          >
            Back
          </button>
          <div className="">
            <Button
              className="bg-main w-fit text-white py-3 px-10 rounded-lg "
              type="submit"
              loading={editProfile.isSubmitting}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </Layout>
  );
}
