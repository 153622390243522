import React, { ReactNode } from "react";
import { motion } from "framer-motion";

type Prop = {
  onClick: () => void;
  children: ReactNode;
};

export default function Backdrop({ children, onClick }: Prop) {
  return (
    <motion.div
      className={
        " absolute h-full w-full top-0 left-0 bg-rgba flex justify-center items-center z-20 "
      }
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}
