// import { Swiper, SwiperSlide } from "swiper/react";
// import {
//   Navigation,
//   EffectFade,
//   Autoplay,
//   Scrollbar,
//   A11y,
//   Pagination,
// } from "swiper/modules";
// import "swiper/css";
// import { FaArrowLeft } from "react-icons/fa";
// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { AppDispatch } from "../../store";
// import { SelectBlog } from "../../store/slice/blog";
// import { getUsersBlogs } from "../../store/service/common";
// import LazyLoad from "react-lazyload";
// import { Link } from "react-router-dom";
// import { trimSentence } from "../../utils/functions";
// import { BsArrowReturnRight } from "react-icons/bs";
// import { Fmtd_Date } from "../../utils/date";
// import ModalP from "../../components/modal/ModalP";
// import { AnimatePresence } from "framer-motion";
// import Blog from "./Blog";
// import useScroll from "../../Hooks/Scroll";

// export default function News() {
//   const dispatch = useDispatch<AppDispatch>();
//   const { list, loading } = useSelector(SelectBlog);
//   const [blogId, setId] = useState("");
//   const [isOpen, setIsOpen] = useState(false);
//   const ToggleModal = () => setIsOpen(!isOpen);
//   const { width } = useScroll();

//   const FetchReferrals = async () => {
//     try {
//       await dispatch(getUsersBlogs());
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     FetchReferrals();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);
//   return (
//     <div className="flex">
//       <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
//         <ModalP modalOpen={isOpen} handleClose={ToggleModal}>
//           <Blog id={blogId} />
//         </ModalP>
//       </AnimatePresence>
//       <div className="flex flex-col md:flex-row bg-black w-full ">
//         <div className="  md:w-[80%] min-h-[100px] flex-col justify-center m-auto text-white ">
//           <p className=" flex md:hidden text-[27px] text-center justify-center items-center py-[10px] ">
//             Latest News & Updates
//           </p>
//           <div className=" ">
//             {!loading && list && list.length !== 0 && (
//               <Swiper
//                 modules={[
//                   Navigation,
//                   EffectFade,
//                   Pagination,
//                   Scrollbar,
//                   A11y,
//                   Autoplay,
//                 ]}
//                 navigation
//                 pagination={{ clickable: true }}
//                 scrollbar={{ draggable: true }}
//                 speed={1000}
//                 spaceBetween={width}
//                 slidesPerView={list?.length > 1 ? 1 : 0}
//                 loop
//                 autoplay={{
//                   delay: 4000,
//                   disableOnInteraction: false,
//                 }}
//               >
//                 {list?.map((item) => (
//                   <LazyLoad key={item.id} height={"40vh"}>
//                     <SwiperSlide>
//                       <div
//                         className=" h-[40vh] md:h-[60vh] bg-center bg-no-repeat  bg-cover  "
//                         style={{ backgroundImage: `url(${item.image})` }}
//                       >
//                         <div className=" flex flex-col  items-center bg-rgba h-[45vh] md:h-[90vh] w-full md:pl-[50px]  ">
//                           <div className=" my-[50px] w-full mx-auto ">
//                             <p className="bg-red-00 text-sub_main text-[16px] md:text-[20px]  w-[95%] md:w[80%]   font-bold text-left  ">
//                               {item.title}
//                             </p>
//                             <p className="bg-red-00 text-sub_main text-[16px] py-4  w-[95%] md:w[80%]   font-bold text-left  ">
//                               {trimSentence(item.description, 50)}
//                             </p>

//                             <p className=" text-[16px] pb-5">
//                               {Fmtd_Date(item.created_at)}
//                             </p>
//                             <button
//                               onClick={() => {
//                                 setId(item.id);
//                                 ToggleModal();
//                               }}
//                               className=" rounded-lg bg-main px-10 py-3 flex items-center gap-4  text-white "
//                             >
//                               View Post <BsArrowReturnRight />
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     </SwiperSlide>
//                   </LazyLoad>
//                 ))}
//               </Swiper>
//             )}
//           </div>
//         </div>
//         <div className=" hidden  md:flex  justify-center items-center w-[20%] bg-[#9DA9B8] text-white text-[50px] ">
//           <FaArrowLeft style={{ fontSize: "30px", paddingRight: "20px" }} />{" "}
//           Blog
//         </div>
//       </div>
//     </div>
//   );
// }

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css";
import { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { SelectBlog } from "../../store/slice/blog";
import { getUsersBlogs } from "../../store/service/common";
import { BsArrowReturnRight } from "react-icons/bs";
import { Fmtd_Date } from "../../utils/date";
import { trimSentence } from "../../utils/functions";
import { AnimatePresence } from "framer-motion";
import ModalP from "../../components/modal/ModalP";
import Blog from "./Blog";

export default function Hero() {
  const dispatch = useDispatch<AppDispatch>();
  const { list, loading } = useSelector(SelectBlog);
  const [blogId, setId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const ToggleModal = () => setIsOpen(!isOpen);

  const FetchData = async () => {
    try {
      await dispatch(getUsersBlogs());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="bg-black">
      <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
        <ModalP modalOpen={isOpen} handleClose={ToggleModal}>
          <Blog id={blogId} />
        </ModalP>
      </AnimatePresence>
      <p className=" flex  text-sub_main text-[27px] text-center justify-center items-center py-[20px] ">
        News & Updates
      </p>
      {!loading && list?.length !== 0 && (
        <Swiper
          modules={[Navigation, EffectFade, Autoplay]}
          navigation
          speed={1000}
          spaceBetween={800}
          slidesPerView={list?.length > 1 ? 1 : 0}
          slidesPerGroupSkip={list?.length > 1 ? 1 : 0}
          loop
          autoplay={{
            delay: 7000,
            disableOnInteraction: false,
          }}
        >
          <SwiperSlide>
            <LazyLoad height={"40vh"}>
              {list &&
                list?.length !== 0 &&
                list?.map((item) => (
                  <div
                    key={item.id}
                    className=" min-h-[40vh] md:h-[60vh] bg-center bg-no-repeat relative bg-cover  "
                    style={{ backgroundImage: `url(${item.image})` }}
                  >
                    <div className=" flex flex-col   items-center bg-rgba h-[45vh] md:h-[90vh] w-full md:pl-[50px]  ">
                      <div className=" my-[50px] w-full mx-auto relative ">
                        <p className="bg-red-00 ml-5 md:ml-0 text-sub_main text-[20px] md:text-[30px]  w-[95%] md:w[80%]   font-bold text-left  ">
                          {item.title}
                        </p>
                        <p className="bg-red-00 ml-5 md:ml-0 text-sub_main text-[16px] py-4  w-[95%] md:w-[70%]   font-bold text-left  ">
                          {trimSentence(item.description, 30)}
                        </p>

                        <p className=" ml-5 text-white md:ml-0 text-[16px] pb-5">
                          {Fmtd_Date(item.created_at)}
                        </p>
                        <button
                          onClick={() => {
                            setId(item.id);
                            ToggleModal();
                          }}
                          className="ml-5 md:ml-0 rounded-lg bg-main px-10 py-3 flex items-center gap-4  text-white "
                        >
                          Read More <BsArrowReturnRight />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </LazyLoad>
          </SwiperSlide>
        </Swiper>
      )}
    </div>
  );
}
