import LayoutOne from "../Layout/Layout_One";
import Hero from "../components/Comp_Layouts/Hero";
import Better from "./component_sections/Better";
import InvestmentFocus from "./component_sections/InvestmentFocus";
import News from "./component_sections/News";
import OnlyAtArk from "./component_sections/OnlyAtArk";
import Principles from "./component_sections/Principles";
import Section1 from "./component_sections/Section1";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../store";
import { getUsersUpdates } from "../store/service/common";
import ModalP from "../components/modal/ModalP";
import { SelectUpdate, setUpdateActive } from "../store/slice/update";
import AccountUpdate from "../components/Comp_Layouts/AccountUpdate";
import { useEffect } from "react";

export default function Landing() {
  const dispatch = useDispatch<AppDispatch>();
  const { active, data } = useSelector(SelectUpdate);

  const init = async () => {
    try {
      await dispatch(getUsersUpdates());
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <LayoutOne>
      <ModalP
        modalOpen={active}
        handleClose={() => {
          dispatch(setUpdateActive(false));
        }}
      >
        <AccountUpdate description={data?.description!} title={data?.title!} />
      </ModalP>
      <Hero />
      <Section1 />
      <div className="hidden md:block ">
        <InvestmentFocus />
      </div>
      <Principles />
      <Better />
      <News />
      <OnlyAtArk />
    </LayoutOne>
  );
}
