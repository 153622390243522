import { useFormik } from "formik";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LoginVal from "../Validation/auth/LoginVal";
import RegisterVal from "../Validation/auth/Register";
import ForgotVal from "../Validation/auth/Forgot";
import ResetVal from "../Validation/auth/Reset";
import {
  login as AuthLogin,
  checkAuth,
  forgotPassword,
  logout,
  registeration,
  resetPassword,
} from "../store/service/auth";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store";
import { routeObj } from "../constants/routes";
import { useEffect } from "react";

const initialLogin = {
  password: "",
  email: "",
};

const initialForgot = {
  email: "",
};

const initialReset = {
  password: "",
  token: "",
};

const initialVRegister = {
  fullname: "",
  country: "",
  email: "",
  password: "",
  phone: "",
  referral: "",
};

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const login = useFormik({
    initialValues: initialLogin,
    validationSchema: LoginVal,
    onSubmit: async (values) => {
      try {
        const { message, data } = await dispatch(AuthLogin(values)).unwrap();
        toast.success(message);
        if (data?.user?.type === "ADMIN") {
          return navigate(routeObj.admins_dashboard);
        }
        navigate(routeObj.users_dashboard);
        return;
      } catch (error) {
        return toast.error(error as string);
      }
    },
  });

  return { login };
};

export const useRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const register = useFormik({
    initialValues: initialVRegister,
    validationSchema: RegisterVal,
    onSubmit: async (values) => {
      try {
        const { message } = await dispatch(registeration(values)).unwrap();
        toast.success(message);
        navigate(routeObj.login);
      } catch (error) {
        console.log(error);
        toast.error(error as string);
      }
    },
  });

  return { register };
};

export const useForgot = () => {
  const dispatch = useDispatch<AppDispatch>();

  const forgot = useFormik({
    initialValues: initialForgot,
    validationSchema: ForgotVal,
    onSubmit: async (values) => {
      try {
        const { message } = await dispatch(forgotPassword(values)).unwrap();
        toast.success(message);
      } catch (error) {
        console.log(error);
        toast.error(error as string);
      }
    },
  });

  return { forgot };
};

export const useReset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const reset = useFormik({
    initialValues: initialReset,
    validationSchema: ResetVal,
    onSubmit: async (values) => {
      try {
        const { message } = await dispatch(resetPassword(values)).unwrap();
        navigate(routeObj.login);
        toast.success(message);
      } catch (error) {
        console.log(error);
        toast.error(error as string);
      }
    },
  });

  return { reset };
};

export const useLogout = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const Logout = async () => {
    try {
      const { message } = await dispatch(logout()).unwrap();
      toast.success(message);
      dispatch({ type: "auth/logout" });
      navigate(routeObj.login);
    } catch (error) {
      console.log(error);
    }
  };

  const Logout_Alt = async () => {
    try {
      dispatch({ type: "auth/logout" });
      navigate(routeObj.login);
    } catch (error) {
      console.log(error);
    }
  };

  return { Logout, Logout_Alt };
};

export const useCheckAuth = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const Authenticated = async () => {
    try {
      const { data } = await dispatch(checkAuth()).unwrap();
      if (!data) {
        dispatch({ type: "auth/logout" });
        navigate(routeObj.login);
        return toast.error("Session Expired");
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    Authenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
