import React from "react";

export default function NotFound() {
  return (
    <div className=" h-[100vh] flex justify-center items-center flex-col ">
      <h1 className=" text-sub_main font-bold text-xl ">
        404 - Page Not Found
      </h1>
      <p className=" text-main ">
        Sorry, the page you are looking for does not exist.
      </p>
    </div>
  );
}
