import { createElement, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { Today } from "../../utils/date";
import { useLocation } from "react-router-dom";
import { adminDashboardDetails } from "../../store/service/adminApi";
import { AppDispatch } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { SelectDashboard } from "../../store/slice/dashboard";
import { GiMoneyStack, GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
import { AiFillProfile } from "react-icons/ai";
import { PuffLoader } from "react-spinners";
import Iframe from "../components/Iframe";
import { getUser } from "../../store/service/auth";
import { getCoin } from "../../store/service/common";
import { numberFormatter } from "../../utils/functions";

export default function AdminDashboard() {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { adminTotal, loading } = useSelector(SelectDashboard);

  const Info: usersInfo = [
    {
      icon: GiPayMoney,
      title: "Total Deposits",
      data: adminTotal.depositTot,
    },
    {
      icon: GiMoneyStack,
      title: "Total Withdrawals",
      data: adminTotal.withdrawalTot,
    },
    {
      icon: GiTakeMyMoney,
      title: "Total Earning",
      data: adminTotal.current,
    },
    {
      icon: AiFillProfile,
      title: "Total Profit",
      data: adminTotal.profitTot,
    },
  ];

  useEffect(() => {
    const init = async () => {
      try {
        await dispatch(getUser());
        await dispatch(adminDashboardDetails());
        await dispatch(getCoin());
      } catch (error) {
        console.log(error);
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <Layout>
      <div className="">
        <p className=" text-[20px] font-[700] ">Overview</p>
        <p>{Today}</p>

        <div className="flex flex-col gap-10 md:gap-3 flex-wrap pt-10 md:flex-row justify-between ">
          {Info.map((value, index) => (
            <div
              key={index}
              className=" w-full md:w-[23%] px-5 py-8 rounded-md min-h-[80px] bg-white  "
            >
              {loading && (
                <div className="">
                  <PuffLoader size={30} />
                </div>
              )}
              {!loading && (
                <div className="flex gap-5 items-center ">
                  <p className=" text-[50px] text-sub_main ">
                    {createElement(value.icon)}
                  </p>
                  <div className=" dash_webkit flex flex-col  overflow-x-scroll ">
                    <p className="">{value.title}</p>
                    <p className="pt-2 ">
                      {value.title !== "Total Referrals"
                        ? `$${numberFormatter(value.data)}`
                        : value.data}
                    </p>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        <Iframe />
      </div>
    </Layout>
  );
}
