import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";

interface NavItemProps {
  name: string;
  link: string;
}

export const FooterLink = ({ link, name }: NavItemProps) => {
  return (
    <Link to={link}>
      <div className="w-fit bg-red-00 pb-2 flex items-center gap-5 text-white">
        <BsChevronRight /> {name}
      </div>
    </Link>
  );
};
