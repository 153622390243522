import { useState } from "react";
import { copyToClipboard } from "../../utils/functions";
import { useMakeDeposit } from "../../Hooks/depositHooks";
import axios from "axios";
import toast from "react-hot-toast";
import { SelectCoin } from "../../store/slice/coin";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
// type Doingek = {
//   btc: string;
//   ethereum: string;
//   xrp: string;
//   usdt: string;
// };

type Props = {
  network: string;
  plan: string;
  closeModal: () => void;
  // data: Doingek;
};

export default function PayupModal({ network, plan, closeModal }: Props) {
  const [clipboardState, setClipboardState] = useState(false);
  const [select, setSelected] = useState<File | null>(null);
  const { deposit } = useMakeDeposit({ closeModal });
  const { data } = useSelector(SelectCoin);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    try {
      e.preventDefault();
      if (deposit.values.amount) {
        if (select) {
          setLoading(true);
          const formData = new FormData();
          formData.append("file", select!);
          formData.append(
            "upload_preset",
            `${process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}`
          );
          await axios
            .post(`${process.env.REACT_APP_CLOUDINARY_URL}`, formData)
            .then(async (response) => {
              deposit.values.image = response.data.url;
              deposit.values.plan = plan;
              deposit.handleSubmit();
              setLoading(false);
            });
          return;
        } else {
          toast.error("Select deposit Proof to continue");
        }
      } else {
        toast.error("Amount Field is required");
      }
    } catch (error: any) {
      setLoading(false);
      const message = error?.message || error;
      toast.error(message);
    }
  };

  return (
    <div className="]">
      {!loading && !deposit.isSubmitting && (
        <div className=" overflow-scroll dash_webkit pt-10  md:pt-10 md:w-[50vw]  md:min-h-[70vh] ">
          <div className="w-full border-4  mt-0 md:mt-10 flex justify-between items-center border-[#e5e5e5] p-3 ">
            <img
              src="/images/logo.webp"
              loading="lazy"
              alt="logo"
              className={"w-[30%]"}
            />
            <div className=" text-right text-[12px] md:text-[18px] ">
              <b>Payment Modal</b>
              <p>Ark Investment Management - Deposit</p>
            </div>
          </div>
          <div className=" text-[12px] md:text-[16px] mt-10 px-6 py-6 md:py-10 flex items-center justify-between border-2 ">
            <b>Payment Method</b>
            <div className=" flex items-center ">
              <img
                src={`/images/${
                  network === "Bitcoin"
                    ? "bit.png"
                    : network === "Ethereum"
                    ? "ether.png"
                    : network === "xrp"
                    ? "xrp.jpeg"
                    : "tet.png"
                }`}
                className=" h-[30px] w-[40px] "
                alt=""
              />
              {network}
            </div>
          </div>
          <div className=" mt-0 px-6 py-2  text-[12px] md:text-[16px] flex items-center justify-between border-2 ">
            <b>Investment Plan</b>
            <p>{plan}</p>
          </div>
          <div className=" mt-0 px-6 py-2 text-[12px] md:text-[16px]  flex items-center justify-between border-2 ">
            <p className="flex">
              <span className=" hidden md:block ">Minimum</span>
              <span className=" block md:hidden pr-1 ">Min:</span>
              <span className=" hidden md:block pl-1 ">Amount:</span>
              {plan === "Basic"
                ? " $200"
                : plan === "Standard"
                ? " $5,000"
                : plan === "Premium"
                ? " $30,000"
                : plan === "Zenith"
                ? "  $70,000"
                : plan === "Elite"
                ? " $200,000"
                : " $1,000,000"}
            </p>
            <p className=" flex text-[12px] md:text-[16px] ">
              <span className=" hidden md:block ">Maximum</span>
              <span className=" block md:hidden pr-1 ">Max:</span>
              <span className=" hidden md:block pl-1 ">Amount:</span>

              {plan === "Basic"
                ? " $1,000"
                : plan === "Standard"
                ? " $29,999"
                : plan === "Premium"
                ? "$69,999"
                : plan === "Zenith"
                ? "$199,999"
                : plan === "Elite"
                ? "$100,000,000"
                : " N/A"}
            </p>
          </div>
          <div className=" bg-main py-3  text-[12px] md:text-[16px] ">
            <div className=" min-h-[60px] p-3 md:px-7 bg-[#e5e5e5] ">
              <i className=" text-sub_main  ">
                Click Text To Copy Address and Make Payment
              </i>
              <p
                className=" cursor-pointer overflow-scroll dash_webkit "
                onClick={() => {
                  copyToClipboard(
                    network === "Bitcoin"
                      ? data?.btc
                      : network === "Ethereum"
                      ? data?.ethereum
                      : network === "xrp"
                      ? data?.xrp
                      : network === "usdt"
                      ? data?.usdt
                      : ""
                  );
                  setClipboardState(true);
                }}
              >
                {network === "Bitcoin"
                  ? data?.btc
                  : network === "Ethereum"
                  ? data?.ethereum
                  : network === "xrp"
                  ? data?.xrp
                  : network === "usdt"
                  ? data?.usdt
                  : ""}
              </p>
              {clipboardState && <p className=" text-green-600 ">copied</p>}
            </div>
          </div>
          <div className="  mt-0 px-6  py-4  flex flex-col text-[12px] md:text-[16px]   border-2">
            <b className="pb-3">Proof Of Payment:</b>
            <div className="p-4 bg-[#e5e5e5] overflow-x-scroll dash_webkit  rounded-lg ">
              <input
                onChange={(e) => {
                  if (e.target.files) {
                    setSelected(e.target.files[0]);
                  }
                }}
                name="image"
                type="file"
                className=" pt-0"
              />
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="  mt-0 px-6  py-4  flex flex-col text-[12px] md:text-[16px]   border-2">
              <b className="pb-3">Amount Deposited:</b>
              <p className=" text-red-500 text-right ">
                {deposit.errors.amount}
              </p>
              <div className="p-4 bg-[#e5e5e5] rounded-lg ">
                <input
                  onChange={deposit.handleChange}
                  value={deposit.values.amount}
                  name="amount"
                  onBlur={deposit.handleBlur}
                  placeholder="Amount"
                  type="number"
                  className="bg-[#e5e5e5] outline-none  w-full pt-0"
                />
              </div>
            </div>
            <div className=" pt-10 flex gap-5 justify-end items-center ">
              <button
                className=" py-3 px-10 border-2 rounded-lg border-[#d9bf84] "
                aria-label="Discard_button"
                onClick={closeModal}
              >
                Discard
              </button>
              <button
                className="py-3 px-10 text-white bg-main rounded-lg"
                aria-label="Save_button"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      )}
      {loading && (
        <div className=" flex px-32 justify-center items-center ">
          <ScaleLoader />
        </div>
      )}
      {deposit.isSubmitting && (
        <div className=" flex px-32 justify-center items-center ">
          <ScaleLoader />
        </div>
      )}
    </div>
  );
}
