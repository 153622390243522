import React, { useEffect, useState } from "react";
import { userViewBlog } from "../../store/service/common";
import { Fmtd_Date } from "../../utils/date";

export default function Blog({ id }: { id: string }) {
  const [data, setData] = useState<BlogData | null>(null);

  const FetchData = async (id: string) => {
    const { data } = await userViewBlog(id);
    setData(data);
  };

  useEffect(() => {
    if (id) {
      FetchData(id);
    }
  }, [id]);
  return (
    <div className="pt-10 md:w-[50vw] ">
      {data && (
        <div>
          <div className="flex bg-main text-sub_main py-3 my-2 px-3 justify-between items-center   ">
            <p className="">{data?.title}</p>
            <p className=" text-[10px] ">{Fmtd_Date(data?.created_at)}</p>
          </div>
          <div className=" flex p-3  bg-main ">
            <img src={data?.image} alt=" " className=" h-full w-full " />
          </div>
          <div className=" pt-4 ">{data?.description}</div>
        </div>
      )}
      {!data && (
        <div className=" py-6 bg-main text-center text-white ">
          Blog Not Found
        </div>
      )}
    </div>
  );
}
