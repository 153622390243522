import { FaTimes } from "react-icons/fa";
import { motion as m } from "framer-motion";
import { ReactNode } from "react";

export default function Drawer({
  open,
  setIsOpen,
  children,
}: {
  open: boolean;
  setIsOpen: (value: boolean) => void;
  children: ReactNode;
}) {
  return (
    // <AnimatePresence initial={false} mode="wait">
    <m.div
      initial={{ opacity: 0 }}
      animate={{ opacity: open ? 1 : 0 }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ opacity: 0 }}
      className={`${
        open
          ? "block transition ease-in duration-300 "
          : "hidden  transition ease-out duration-300 "
      } fixed w-full h-[100vh] md:hidden z-50  bg-main `}
    >
      <div className=" h-full bg-green-00 pt-5 relative  ">
        <button
          id="menu-close"
          name="menu-close"
          className=" absolute right-5 md:right-[18rem] "
          onClick={() => setIsOpen(false)}
        >
          <FaTimes size={25} fill="#d9bf84" />
        </button>
        {children}
      </div>
    </m.div>
    // </AnimatePresence>
  );
}
