import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import Drawer from "../../components/Drawer";
import { FiBell } from "react-icons/fi";
import Modal from "../../components/modal";
import SideBar2 from "./SideBar2";
import { CgProfile } from "react-icons/cg";
import NotificationM from "../../components/NotificationM";
import { SelectAuth } from "../../store/slice/auth";
import { useSelector } from "react-redux";

export default function NavBar() {
  const { user } = useSelector(SelectAuth);
  const [isOpen, setIsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const OpenModal = () => setModalOpen(true);
  const CloseModal = () => setModalOpen(false);

  return (
    <div>
      <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
        <Drawer open={isOpen} setIsOpen={setIsOpen}>
          <SideBar2 />
        </Drawer>
      </AnimatePresence>
      {modalOpen && (
        <Modal modalOpen={modalOpen} handleClose={CloseModal}>
          <NotificationM />
        </Modal>
      )}
      <div className=" h-[100px] py-6 border-2 border-[#d9bf84] md:h-[123px]  flex items-center justify-between text-white bg-main ">
        <div className=" flex items-center gap-5 md:gap-5 ml-5 ">
          <GiHamburgerMenu
            className={`${isOpen ? "rotate-180" : ""} md:hidden `}
            fill="#d9bf84"
            onClick={() => setIsOpen(!isOpen)}
          />
          <img loading="lazy" src="/images/logo.webp" alt="logo" className="" />
        </div>
        <div className="flex h-full gap-5 justify-between items-center pr-5 ">
          <FiBell
            onClick={() => {
              modalOpen ? CloseModal() : OpenModal();
            }}
            // fill="#d9bf84"
            className="text-[#d9bf84] cursor-pointer text-[20px] "
          />
          {user?.image ? (
            <img src={user?.image} className=" h-[70%] rounded-full " alt="" />
          ) : (
            <CgProfile className="text-[50px]" />
          )}
          <p className=" hidden md:block ">{user?.fullname}</p>
        </div>
      </div>
      {/* <CoinLib /> */}
    </div>
  );
}
