import React, { useEffect } from "react";
import { SelectNotification } from "../store/slice/notification";
import { useDispatch, useSelector } from "react-redux";
import { BiMessage } from "react-icons/bi";
import { PuffLoader } from "react-spinners";
import { replaceSpecialCharsWithSpace } from "../utils/functions";
import { AppDispatch } from "../store";
import { getNotifications } from "../store/service/common";
import { Link } from "react-router-dom";
import { routeObj } from "../constants/routes";
import { CgArrowRight } from "react-icons/cg";

export default function NotificationM() {
  const { list, loading } = useSelector(SelectNotification);
  const dispatch = useDispatch<AppDispatch>();

  const FetchNotification = async (params: { page: number } | null) => {
    try {
      await dispatch(getNotifications(params));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchNotification(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className=" bg-red-00 w-full py-5 px-5 ">
      <div className="flex justify-between items-center ">
        <p className=" font-bold text-[30px]  ">Notifications</p>
      </div>
      {loading && (
        <div className=" pt-5">
          <PuffLoader size={30} />
        </div>
      )}
      {!loading &&
        list?.slice(0, 4)?.map((items) => (
          <div key={items.id} className=" pt-5 pb-3 border-b-[1px] ">
            <div className=" flex items-center gap-6 ">
              <div className=" text-[18px] rounded-full p-2 bg-[#e5e5e5] ">
                <BiMessage />
              </div>
              <div className="">
                <p className=" text-[15px] uppercase font-bold pb-2 ">
                  {replaceSpecialCharsWithSpace(items.title)}
                </p>
                <p className=" text-[12px] font-medium pb- ">{items.preview}</p>
                <p className=" text-[12px] font-medium pb- ">{items.time}</p>
              </div>
            </div>
          </div>
        ))}
      <Link
        to={routeObj.notification}
        className=" flex gap-4 items-center justify-center text-center mt-5 font-bold   "
      >
        View All <CgArrowRight />
      </Link>
    </div>
  );
}
