import { memo, useEffect } from "react";
import Layout from "../../Layout/Layout";
import Pagination from "../../components/Pagination";
import { routeObj } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import { SelectAdmin } from "../../store/slice/admin";
import { getUsers } from "../../store/service/adminApi";
import { ScaleLoader } from "react-spinners";

export default function AllUsers() {
  const dispatch = useDispatch<AppDispatch>();
  const { users, loading, pagination } = useSelector(SelectAdmin);
  const navigate = useNavigate();
  const FetchData = async (params: { page: number } | null) => {
    try {
      await dispatch(getUsers(params));
    } catch (error) {
      console.log(error);
    }
  };

  const sendRequest = (val: number) => {
    return FetchData({ page: val });
  };

  useEffect(() => {
    FetchData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Layout>
      <div className=" relative  min-w-0 break-words w-full  ">
        <p className=" text-[20px] md:text-[25px] font-[700] ">Users</p>
        <div className="dash_webkit block pt-10 w-full overflow-x-auto ">
          <table className=" bg-red-00 w-full md:w-full ">
            <thead className=" mb-7 ">
              <tr className=" text-left  border-b-2  border-[#d7d7d7] ">
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Name
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Email
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Country
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Phone
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Crypto
                </th>
                <th className=" pb-7 px-3 tracking-wide whitespace-nowrap  ">
                  Address
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-[#d7d7d7]">
              {!loading &&
                users.length !== 0 &&
                users?.map((user) => (
                  <TableRow
                    key={user.id}
                    name={user.fullname}
                    email={user.email}
                    country={user.country}
                    phone={user.phone}
                    crypto={user.crypto ?? "Null"}
                    address={user.address ?? "Null"}
                    id={user.id}
                    onClick={(id: string) => {
                      navigate(`${routeObj.edit_account}${id}`);
                    }}
                  />
                ))}

              {!loading && users.length === 0 && (
                <tr>
                  <td className="py-0 text-center " colSpan={6}>
                    <div className="w-full py-10 font-bold bg-main text-white text-[20px] flex justify-center items-center ">
                      You Don't Have Any User
                    </div>
                  </td>
                </tr>
              )}

              {loading && (
                <tr>
                  <td className="py-6 text-center " colSpan={6}>
                    <div className="w-full py-1 bg-red-00 flex justify-center items-center ">
                      <ScaleLoader />
                    </div>
                  </td>
                </tr>
              )}

              <tr>
                <td className="pt-6" colSpan={6}>
                  <Pagination
                    currentPage={pagination?.current}
                    totalPages={pagination?.total}
                    sendRequest={sendRequest}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
}

const TableRow = memo(
  ({ id, name, email, country, phone, crypto, address, onClick }: any) => {
    return (
      <tr
        className={`text-left cursor-pointer border-b-2 border-[#d7d7d7]`}
        onClick={() => onClick(id)}
      >
        <td className="py-5 whitespace-nowrap px-3">{name}</td>
        <td className="py-5 whitespace-nowrap px-3">{email}</td>
        <td className="py-5 whitespace-nowrap px-3">{country}</td>
        <td className="py-5 whitespace-nowrap px-3">{phone}</td>
        <td className="py-5 whitespace-nowrap px-3">{crypto}</td>
        <td className="py-5 whitespace-nowrap px-3">{address}</td>
      </tr>
    );
  }
);
