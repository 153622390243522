import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css";
import { useEffect } from "react";
import LazyLoad from "react-lazyload";

export default function Hero() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="bg-black">
      <Swiper
        modules={[Navigation, EffectFade, Autoplay]}
        navigation
        speed={1000}
        spaceBetween={800}
        slidesPerView={1}
        loop
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <LazyLoad height={"40vh"}>
            <div
              className=" h-[40vh] md:h-[90vh] bg-center bg-no-repeat bg-cover relative "
              style={{ backgroundImage: `url('/images/dash2.jpg')` }}
            >
              <div className=" flex bg-rgba h-[45vh] md:h-[90vh] w-full md:pl-[50px]  ">
                <div className=" my-[50px] mx-auto ">
                  <p className=" p-[20px] text-sub_main text-[16px] md:text-[20px] bg-rgb w-[95%] md:w-[80%] font-bold text-center m-auto ">
                    Regardless of your proficiency level, our expertise can
                    assist you in maximizing your financial resources.
                  </p>
                </div>
              </div>
            </div>
          </LazyLoad>
        </SwiperSlide>

        <SwiperSlide>
          <LazyLoad height={"40vh"}>
            <div
              className=" h-[40vh] md:h-[90vh] bg-center bg-no-repeat bg-cover relative "
              style={{ backgroundImage: `url('/images/dash3.jpg')` }}
            >
              <div className=" flex bg-rgba h-[45vh] md:h-[90vh] w-full md:pl-[50px]  ">
                <div className=" my-[50px] mx-auto ">
                  <p className=" p-[20px] text-sub_main text-[16px] md:text-[20px] bg-rgb w-[95%] md:w-[80%] font-bold text-center m-auto ">
                    Our purpose, when realized, holds the power to
                    transformations and leave a lasting imprint on the world.
                  </p>
                </div>
              </div>
            </div>
          </LazyLoad>
        </SwiperSlide>

        <SwiperSlide>
          <LazyLoad height={"40vh"}>
            <div
              className=" h-[40vh] md:h-[90vh] bg-center bg-no-repeat bg-cover relative "
              style={{ backgroundImage: `url('/images/dash.jpg')` }}
            >
              <div className=" flex bg-rgba h-[45vh] md:h-[90vh] w-full md:pl-[50px]  ">
                <div className=" my-[50px] mx-auto ">
                  <p className=" p-[20px] text-sub_main text-[16px] md:text-[20px] bg-rgb w-[95%] md:w-[80%] font-bold text-center m-auto ">
                    We are dedicated to generating wealth through strategic
                    investments, fostering financial growth and prosperity.
                  </p>
                </div>
              </div>
            </div>
          </LazyLoad>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}
