import React from "react";
import Layout from "../../Layout/Layout";
import { FormInput } from "../../components/Comp_Layouts/Form";
import { useAddress } from "../../Hooks/userHooks";
import { useSelector } from "react-redux";
import { SelectCoin } from "../../store/slice/coin";
import { Button } from "../../components/Button";

export default function Address() {
  const { data } = useSelector(SelectCoin);
  const { address } = useAddress(data);

  return (
    <Layout>
      <div className=" text-[20px] font-bold ">Address</div>
      <form onSubmit={address.handleSubmit}>
        <div className=" mt-10 grid grid-cols-1 md:grid-cols-2 gap-8">
          <FormInput
            name="btc"
            className=""
            label="Bitcoin"
            margin={1}
            type="text"
            error={address.errors.btc!}
            onBlur={address.handleBlur}
            onChange={address.handleChange}
            value={address.values.btc}
          />

          <FormInput
            name="ethereum"
            className=""
            label="Ethereum"
            margin={1}
            type="text"
            error={address.errors.ethereum!}
            onBlur={address.handleBlur}
            onChange={address.handleChange}
            value={address.values.ethereum}
          />

          <FormInput
            name="usdt"
            className=""
            margin={1}
            label="Usdt"
            type="text"
            error={address.errors.usdt!}
            onBlur={address.handleBlur}
            onChange={address.handleChange}
            value={address.values.usdt}
          />

          <FormInput
            name="xrp"
            className=""
            margin={1}
            type="text"
            label="XRP"
            error={address.errors.xrp!}
            onBlur={address.handleBlur}
            onChange={address.handleChange}
            value={address.values.xrp}
          />
        </div>
        <div className="  mt-10 w-full gap-5 bg-red-00 flex justify-end ">
          <Button
            loading={address.isSubmitting}
            className="py-3 w-full md:w-[200px] px-10 bg-main text-white rounded-lg"
            type="submit"
          >
            Update
          </Button>
        </div>
      </form>
    </Layout>
  );
}
