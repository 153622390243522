import { useDispatch, useSelector } from "react-redux";
import { SelectAuth } from "../../store/slice/auth";
import { Button } from "../../components/Button";
import { useState } from "react";
import { AppDispatch } from "../../store";
import { UpdateAccount } from "../../store/service/auth";
import toast from "react-hot-toast";
import { useGetUser } from "../../Hooks/getUser";

type Props = {
  onClose: () => void;
};
export default function ChangeAcc({ onClose }: Props) {
  const { user } = useSelector(SelectAuth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { FetchUser } = useGetUser();

  const handleChange = async () => {
    try {
      setLoading(true);
      const req_data = {
        account_mode: user?.account_mode === "BUSINESS" ? "WEEKLY" : "BUSINESS",
      };
      const { message } = await dispatch(UpdateAccount(req_data)).unwrap();
      await FetchUser();
      onClose();
      setLoading(false);
      return toast.success(message);
    } catch (error) {
      setLoading(false);
      onClose();
    }
  };

  return (
    <div className="p-2">
      <p className=" text-center font-bold text-[20px] ">Change Of Account</p>
      <p className=" text-center pt-4 ">
        Confirm Change of Account From{" "}
        {user?.account_mode === "WEEKLY" ? "Weekly Mode" : "Business Mode"} to{" "}
        {user?.account_mode === "BUSINESS" ? "Weekly Mode" : "Business Mode"}
      </p>
      <div className="w-[50%] m-auto ">
        <Button
          loading={loading}
          onClick={() => handleChange()}
          className=" bg-sub_main m-auto py-2 px-5 rounded-lg "
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}
