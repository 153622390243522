import { NavUrls } from "../utils/routes";
import CoinLib from "./CoinLib";
import { NavLink } from "./Comp_Layouts/NavLink";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";
import Drawer from "./Drawer";
import { AnimatePresence } from "framer-motion";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className=" ">
      <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
        <Drawer open={isOpen} setIsOpen={setIsOpen}>
          <div
            className={`w-[100%] bg-yellow-0 h-full flex flex-col justify-between pb-5 px-10 text-[18px]`}
          >
            {NavUrls?.map((item) => (
              <NavLink key={item.id} {...item} />
            ))}
          </div>
        </Drawer>
      </AnimatePresence>

      <div className=" hidden md:block ">
        <CoinLib />
      </div>
      <div className="h-[100px] bg-main">
        <div className="w-[95%] md:w-[90%] h-full m-auto bg-hite flex justify-between items-center ">
          <div className="w-fit  bg-red-00  ">
            <img
              src="/images/logo.webp"
              alt="logo"
              loading="lazy"
              className=""
            />
          </div>
          <div className="w-[80%] hidden  bg-green-00 text-[20px] font-bold   md:flex justify-between items-center ">
            {NavUrls?.map((item) => (
              <NavLink key={item.id} {...item} />
            ))}
          </div>
          <button
            id="menu"
            name="menu"
            className=" py-2 px-3 md:hidden mr-4  border-2 border-[#d9bf84] "
            onClick={() => setIsOpen(!isOpen)}
          >
            <GiHamburgerMenu
              className={`${isOpen ? "rotate-180" : ""}`}
              fill="#d9bf84"
            />
          </button>
        </div>
      </div>
      <div className="h-[25px] bg-sub_main"></div>
      <div className=" bg-[#56667F]  md:hidden ">
        <CoinLib />
      </div>
    </div>
  );
}
