import { useNavigate } from "react-router-dom";
import LayoutOne from "../Layout/Layout_One";
import Imgsection from "../components/Comp_Layouts/HeroTwo";
import { motion } from "framer-motion";
import { inv_types } from "../utils/data";
import { routeObj } from "../constants/routes";

export default function Packages() {
  const text_query = "text-[14px] md:text-[18px]";
  const navigate = useNavigate();
  return (
    <LayoutOne>
      <Imgsection
        image="/images/banner34.jpg"
        sub_title="Our Investment Plans"
        title="Packages"
      />

      <div className="pt-[30px] pb-[70px] bg-blue-00 flex flex-col  items-center justify-between min-h-[500px]  ">
        <h1 className=" pb-[20px] text-[22px] md:text-[30px] text-sub_main ">
          PACKAGES
        </h1>
        <p className={`w-[80%] md:w-[50%] text-center ${text_query}`}>
          Ark Investment Financial Management gives investors the opportunity to
          choose from a variety of investment packages and earn every week.{" "}
        </p>
        <div
          className={`flex w-[95%] md:w-[90%] gap-7 px-[20px] md:px-[50px] m-auto flex-wrap flex-col md:flex-row justify-between pt-5 text-center ${text_query}`}
        >
          {inv_types?.map((inv, index) => (
            <div
              key={index}
              className=" mt-10  w-[98%] m-auto md:w-[30%] bg-red-00 "
            >
              <div className=" h-[200px] flex justify-center items-center bg-main rounded-tr-lg rounded-tl-lg ">
                <img className=" h-[60%] " src={inv.picture} alt=" " />
              </div>
              <div className=" border-2  border-[#728191] ">
                <p className=" text-center py-3 border-b-2 border-[#728191] text-[20px] font-[700]  ">
                  {inv.plan} Plan
                </p>
                <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
                  Minimum Amount : {inv.min}
                </p>
                <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
                  Maximum Amount : {inv.max}
                </p>
                <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
                  Referral : {inv.ref}
                </p>
                <p className="text-center py-3 border-b-2 border-[#728191] text-[16px]">
                  Weekly ROI : {inv.weekly}
                </p>
                <div className=" bg-red-00 flex items-center text-center py-4 border-b-2 border-[#728191] text-[16px] ">
                  <button
                    className=" m-auto py-4 px-10 rounded-xl text-white bg-main"
                    onClick={() => {
                      navigate(`${routeObj.deposit_type}${inv.plan}`);
                    }}
                  >
                    Deposit
                  </button>
                </div>
              </div>
            </div>
          ))}
          {/* {inv_plans?.map((plan) => (
            <div key={plan?.id} className="md:w-[32%]">
              <div className=" min-h-[280px] md:min-h-[300px] flex flex-col w-full shadow-lg rounded-md border-2 border-[#ccb884] mt-[40px] md:mt-[50px] pt-[30px] ">
                <p className="text-[30px] md:text-[50px]">{plan?.amount}</p>
                <p className="text-[20px] pb-[30px] ">{plan?.type} Plan</p>
                <p className="border-t-2 border-b-2 text-[18px] py-[10px] ">
                  <b>Minimum Amount</b>: {plan?.min}
                </p>
                <p className="border-t-2 border-b-2 text-[18px] py-[10px] ">
                  <b>Maximum Amount</b>: {plan?.max}
                </p>
                <p className="border-t-2 border-b-2 text-[18px] py-[10px] ">
                  <b>Weekly Profit</b>: {plan?.profit}
                </p>
                <p className="border-t-2 border-b-2 text-[18px] py-[10px] ">
                  <b>Referral Percentage</b>: {plan?.referral}
                </p>
                <p className="border-t-2 border-b-2 text-[18px] py-[10px] ">
                  <b>Capital Insurance</b>: Available
                </p>
                <Link to={"/"} className="py-[30px] ">
                  <p className=" bg-sub_main w-fit m-auto px-10 py-2 rounded-xl text-[20px] text-main ">
                    <b>Get Started</b>
                  </p>
                </Link>
              </div>
            </div>
          ))} */}
        </div>
      </div>

      <div
        className=" min-h-[50vh] md:min-h-[90vh] flex flex-col md:flex-row bg-center bg-cover bg-no-repeat "
        style={{ backgroundImage: `url('/images/banner3.jpg')` }}
      >
        <div className="flex items-center bg-rgba py-[20px] w-full pl-[10px] md:pl-[50px] ">
          <div className="w-full min-h-[400px] flex flex-col justify-center pl-[20px]  ">
            <h1 className=" text-[20px] md:text-[30px] text-white text-center ">
              EARN 10% FOR YOU, 15% FOR THEM
            </h1>
            <p
              className={` py-[25px] w-full text-white text-center ${text_query}`}
            >
              We are here for a reason- to spread the wealth.
            </p>
            <div className=" w-full flex flex-wrap flex-col md:flex-row justify-center md:justify-between ">
              <div className=" w-[90%] md:w-[25%] shadow-xl md:shadow-none text-[#d7d7d7] h-[220px] md:h-[200px] p-[10px] md:ml-[20px] mt-[20px] flex flex-col ">
                <div className="flex w-[20%] items-center justify-center m-auto ">
                  <motion.img
                    whileHover={{ rotateX: -120 }}
                    className="transition-all h-[30px] w-full "
                    src="/images/5.png"
                    alt="djk dssd sdjsd"
                  />
                </div>
                <div className="flex justify-center items-center py-[10px] w-full font-bold ">
                  Share
                </div>
                <div
                  className={`flex justify-center items-center w-full text-center ${text_query} `}
                >
                  Share your custom link with your friends via social media or
                  email.
                </div>
              </div>
              <div className="w-[90%] md:w-[25%] shadow-xl md:shadow-none text-[#d7d7d7] h-[220px] md:h-[200px] p-[10px] md:ml-[20px] mt-[20px] flex flex-col ">
                <div className="flex w-[20%] items-center justify-center m-auto">
                  <motion.img
                    whileHover={{ rotateX: -120 }}
                    className="transition-all h-[30px] w-full"
                    src="/images/3.png"
                    alt="fgffgd d dfdfdf"
                  />
                </div>
                <div className="flex justify-center items-center py-[10px] w-full font-bold">
                  Register
                </div>
                <div
                  className={`flex justify-center items-center w-full text-center ${text_query} `}
                >
                  Your friends follow the link to complete a few simple
                  instructions.
                </div>
              </div>
              <div className="w-[90%] md:w-[25%] shadow-xl md:shadow-none text-[#d7d7d7] h-[220px] md:h-[200px] p-[10px] md:ml-[20px] mt-[20px] flex flex-col">
                <div className="flex w-[20%] items-center justify-center m-auto">
                  <motion.img
                    whileHover={{ rotateX: -120 }}
                    className="transition-all h-[30px] w-full"
                    src="/images/4.png"
                    alt="fgffgd d dfdfdf"
                  />
                </div>
                <div className="flex justify-center items-center py-[10px] w-full font-bold">
                  Earn
                </div>
                <div
                  className={`flex justify-center items-center w-full text-center ${text_query}`}
                >
                  Once your friend makes a direct deposit, you will earn 10%.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`bg-main py-[55px] ${text_query}`}>
        <p className=" w-[78%] m-auto text-white">
          When a friend uses your referral link (email address) and opens an
          account with direct deposits and enrolls in e-statements (Proof of
          payment), Ark will deposit 10% into your account.
        </p>
      </div>
    </LayoutOne>
  );
}
