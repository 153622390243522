import { ReactNode } from "react";
import classNames from "classnames";
import Backdrop from "./Backdrop";
import { motion } from "framer-motion";
import { FaTimes } from "react-icons/fa";

type Prop = {
  handleClose: () => void;
  children?: ReactNode;
  modalOpen: boolean;
  className?: string;
  width?: number;
};

export default function Modal({
  handleClose,
  modalOpen,
  className,
  children,
  width,
}: Prop) {
  const DropIn = {
    hidden: {
      y: "-100vh",
      opacity: 0,
    },
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.8,
        type: "spring",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
    },
  };

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className={classNames(
          ` min-h-[30%] w-[95%] md:w-[${
            width ? width : 40
          }%] rounded-lg flex justify-center items-center relative bg-[#ffffff]  ${className} `
        )}
        variants={DropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <button
          onClick={handleClose}
          className={
            " absolute right-4 top-5 cursor-pointer text-black text-[20px] "
          }
        >
          <FaTimes />
        </button>
        {children}
      </motion.div>
    </Backdrop>
  );
}
